<template>
  <modal @close="close()" title="Регистрация домена">
    <form class="form-container" @submit.prevent="domainCreate()">
      <div class="settings-tabs">
        <settings-tab :activeTab.sync="activeTab" :num="1"
          >Регистрация домена</settings-tab
        >
        <settings-tab :activeTab.sync="activeTab" :num="2"
          >Перенос домена от другого регистратора</settings-tab
        >
      </div>

      <div class="form-create" v-if="activeTab == 1">
        <div class="form-col">
          <div class="form-group">
            <label>Доменное имя</label>
            <input type="text" class="c-input" placeholder="sitename.com" />
          </div>
        </div>
        <div class="form-col">
          <div class="form-group">
            <label>Доменная зона</label>
            <c-dropselect
              :options="['Вариант 1', 'Вариант 2']"
              placeholder="RU 200 ₽ / в год"
              select="action"
              width="250px"
            >
            </c-dropselect>
          </div>
        </div>
      </div>
      <div class="form-create" v-if="activeTab == 2">
        <div class="form-col">
          <div class="form-group">
            <label>Доменное имя</label>
            <input type="text" class="c-input" placeholder="sitename.com" />
          </div>
        </div>
        <div class="form-col">
          <div class="form-group">
            <label>Код переноса</label>
            <input type="text" class="c-input" placeholder="Код" />
          </div>
        </div>
      </div>

      <div class="form-submit no-spacing flex f-justify-center">
        <button
          type="submit"
          class="btn button button-primary large font-bold"
          :class="{ disabled: isLoading }"
        >
          <div class="loading-spinner white wtext" v-if="isLoading" />
          <template>
            <span v-if="activeTab == 1"> Зарегистрировать домен </span>
            <span v-if="activeTab == 2"> Перенести домен </span>
          </template>
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import SettingsTab from "@/components/Tabs/settings-tab.vue";
import CDropselect from "../Form/с-dropselect.vue";

export default {
  data() {
    return {
      activeTab: 1,
    };
  },
  components: {
    Modal,
    SettingsTab,
    CDropselect,
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    //
    domainCreate() {
      console.debug("Create domain clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal {
  width: 830px;
  height: auto;
  min-height: 530px;
}
::v-deep .modal-container {
  max-width: 600px;
}
::v-deep .modal-container .modal-header {
  margin-bottom: 30px;
  margin-top: 80px;
}
::v-deep .settings-tabs {
  display: flex;
  margin-top: 0;
  margin-bottom: 50px;
}
::v-deep .tab-content:after {
  bottom: -15px;
}
.form-container {
  width: 600px;
  max-width: 100%;
}
.c-input {
  width: 250px;
}
.form-group {
  label {
    font-weight: 500;
  }
}
</style>
