<template>
  <label class="c-radio">
    <input class="" type="radio" :value="onval" v-model="value" />
    <div class="checkmark"></div>
    <slot> Default Radio </slot>
  </label>
</template>
<script>
export default {
  data() {
    return {
      value: this.val,
    };
  },
  props: {
    onval: {
      type: String,
      required: true,
    },
    val: {
      required: true,
    },
  },
  watch: {
    val(newVal) {
      this.value = newVal;
    },
    value(newVal) {
      if (newVal) {
        this.$emit("update:val", newVal);
      }
    },
  },
};
</script>
