const axios = require('axios').default;


class ApiClient {
    base_url = "/api"
    constructor(params) {
        this.params = params || {}
        // 

        // 
        this.axios = axios.create({
            baseURL: this.base_url,
            headers: this.getAuthHeaders()
        })
    }

    updateAuthHeaders() {
        this.axios.defaults.headers.common = Object.assign({}, this.axios.defaults.headers.common, this.getAuthHeaders())
        if (!this.params.access_token) {
            delete this.axios.defaults.headers.common["Authorization"]
        }
    }

    getAuthHeaders() {
        let headers = {
            "Accept": "application/json",
            // "Authorization": "",
        }
        if (this.params.access_token) {
            headers["Authorization"] = "Bearer " + this.params.access_token
        }
        return headers
    }

    // abs_path(sub_url) {
    //     // Return absolute path to sub_url
    //     return this.base_url + sub_url
    // }

    handleError(err) {
        console.warn("Axios request errr: ", err)
    }

    // Authentication and user profile

    getUserProfile() {
        return this.axios.get("/profile", {})//.catch(this.handleError)
    }
    getUserTariff() {
        return this.axios.get("/tariff")
    }

    userLogin(email, password) {
        return this.axios.post("/auth/login", { email: email, password: password })
    }
    userRegister(name, email, password) {
        return this.axios.post("/auth/register", { name: name, email: email, password: password })
    }
    updateUserPassword(curr, new1, new2) {
        return this.axios.put("/auth/change/password", { old_password: curr, password: new1, confirm_password: new2 })
    }

    updateIPS(data) {
        return this.axios.put("/auth/ip", data)
    }

    // -- Companies

    getCompanies(page = 1) {
        return this.axios.get("/company", { page: page })
    }
    createCompany(data) {
        return this.axios.post("/company", data)
    }
    updateCompany(id, data) {
        return this.axios.put("/company/" + id, data)
    }
    deleteCompany(id) {
        return this.axios.delete("/company/" + id)
    }

    // -- Whitepages 

    getWhitepages() {
        return this.axios.get("/white")
    }
    updateWhitepage(id, data, config) {
        if (id) {
            return this.axios.put("/white" + id, data, config)
        } else {
            return this.axios.post("/white", data, config)
        }
    }
    deleteWhitepage(id) {
        return this.axios.delete("/white/" + id)
    }

    // -- Black pages

    getBlackpages(data) {
        return this.axios.get("/black", data)
    }
    updateBlackpage(id, data, config) {
        if (id) {
            return this.axios.put("/black" + id, data, config)
        } else {
            return this.axios.post("/black", data, config)
        }
    }
    deleteBlackpage(id) {
        return this.axios.delete("/black/" + id)
    }

    // -- Tariffs

    // getTariffs() {
    //     return this.axios.get("/tariff")
    // }

    setTariff(tariff, date) {
        return this.axios.post("/tariff", {
            tariff: tariff,
            date: date,
        })
    }
}

export default ApiClient
