<template>
  <div
    class="c-select"
    :class="{ open: open, disabled: disabled }"
    :style="{ width: width }"
  >
    <div class="c-selected" :class="{ open: open }" @click="onOpenClicked()">
      <input
        class="c-select-input"
        :value="selected"
        :placeholder="
          placeholder ||
          (Array.isArray(options[0]) ? options[0][1] : options[0]) ||
          'Select'
        "
        readonly
      />
      <span class="icon-select" :class="{ open: open }">
        <svg
          v-svg
          :symbol="open ? 'arrow_up' : 'arrow_down'"
          size="0 0 24 24"
          role="presentation"
          fill="black"
          class="icon--inline icon-dropdown"
        ></svg>
      </span>
    </div>

    <div class="items" :class="{ selectHide: !open }">
      <div
        class="option"
        v-for="(option, i) of options"
        :key="i"
        @click="onItemClicked(option)"
      >
        {{ Array.isArray(option) ? option[1] : option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      // Can be ['Opt1', 'Opt2'] or [['opt_id', 'Opt1 text'], ['opt_id2', 'Opt2 text']]
      default: () => ["Вариант 1", "Вариант 2"],
      // required: true,
    },
    default: {
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
    },
    width: {
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
    },
    value: {},
  },
  data() {
    return {
      selected: this.default,
      open: false,
    };
  },
  methods: {
    hideSelect(e) {
      let clicked_this = this.$el.contains(e.target);

      if (clicked_this) {
        e.preventDefault();
        return;
      } else {
        this.open = false;
      }
    },
    onOpenClicked() {
      if (this.disabled) {
        return; // Disable
      }
      this.open = !this.open;
    },
    onItemClicked(option) {
      let option_name = option;
      if (Array.isArray(option)) {
        option_name = option[1];
        option = option[0];
      }

      this.selected = option_name;
      this.open = false;
      this.$emit("select", option);
      this.$emit("update:value", option);
    },
  },
  mounted() {
    document.addEventListener("click", this.hideSelect.bind(this), true);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.hideSelect);
  },
};
</script>

<style lang="scss" scoped>
.icon-dropdown {
  fill: #a7b1c0;

  .dark & {
    fill: #a9b6cb;
  }
}
.c-select-input {
  background: inherit;
}
.c-select {
  &.disabled {
    background: #f2f3f8;

    .dark & {
      background: #222532;
    }
  }
}
</style>
