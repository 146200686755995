<template>
  <input
    :placeholder="placeholder"
    :value="value"
    @input="Update($event.target.value)"
    class="c-input"
    :class="cls"
    :type="type"
  />
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
    },
    value: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    cls: {
      default: "",
    },
  },
  methods: {
    Update(text) {
      this.$emit("input", text);
    },
  },
};
</script>

<style></style>
