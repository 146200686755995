<template>
  <div class="container login-form-container flex-column">
    <form class="login-form" @submit.prevent="submitLogin()">
      <div class="head">
        <h1 class="text-center">Авторизация</h1>
      </div>

      <div class="alert alert-danger text-center" v-if="formLoginError">
        Неверный Email или пароль
      </div>

      <div class="form-input">
        <c-input
          placeholder="Email"
          :value="formEmail"
          @input="formEmail = $event"
        ></c-input>
      </div>
      <div class="form-input">
        <c-input
          placeholder="Пароль"
          :type="showPassword ? 'text' : 'password'"
          :value="formPassword"
          @input="formPassword = $event"
        ></c-input>
      </div>

      <div class="form-group">
        <label class="c-check">
          <input type="checkbox" v-model="rememberEmail" />
          <div class="checkmark"></div>
          Запомнить Email
        </label>
      </div>

      <div class="text-center">
        <button class="btn btn-submit" type="submit" :disabled="isLoading">
          <div class="loading-spinner spinner-submit" v-if="isLoading"></div>
          Войти
        </button>
      </div>
    </form>

    <div class="no-account-wrapper">
      <h5>
        Нет аккаунта?
        <a href="#" @click.prevent="routeRegister()">Зарегистрироваться</a>
      </h5>
    </div>
  </div>
</template>

<script>
import CInput from "../Form/c-input.vue";
import ApiClient from "@/api/api_client.js";
import { setAccessToken, getAccessToken } from "@/api/localstorage.js";

export default {
  data() {
    let lastEmail = localStorage.getItem("lastEmail");
    let rememberEmail = localStorage.getItem("rememberEmail");
    return {
      formEmail: lastEmail,
      formPassword: null,
      showPassword: false,
      formLoginError: false,
      rememberEmail: rememberEmail,
      lastEmail: lastEmail,
      isLoading: false,
    };
  },

  components: {
    CInput,
  },

  created() {
    if (getAccessToken()) {
      this.onLoggedIn(false);
    }
  },

  methods: {
    submitLogin() {
      console.log("Submig login.");
      this.isLoading = true;
      this.formLoginError = false;
      this.$apiClient
        .userLogin(this.formEmail, this.formPassword)
        .then((resp) => {
          console.log("Logged in:", resp);

          let token = resp.data.access_token;
          // console.debug("Setting session token:", token);
          setAccessToken(token);

          this.onLoggedIn(true);
        })
        .catch((err) => {
          console.warn("Login error: ", err);
          this.formLoginError = true;
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    routeRegister() {
      this.$router.push("/app/register").catch(() => {});
    },

    onLoggedIn(force) {
      let main_comp = this.$parent;
      main_comp.getUserProfile(force); // Update main component user profile
      // this.$router.push("/app/campaigns").catch(() => {});
    },
    updateRememberEmail() {
      localStorage.setItem("rememberEmail", this.rememberEmail ? "yes" : "");
      if (this.rememberEmail) {
        localStorage.setItem("lastEmail", this.formEmail || "");
      } else {
        localStorage.removeItem("lastEmail");
      }
    },
  },
  watch: {
    formEmail(newVal) {
      this.updateRememberEmail();
    },
    rememberEmail(newVal) {
      this.updateRememberEmail();
    },
  },
};
</script>

<style lang="scss">
.login-form {
  max-width: 500px;
  margin: 0 auto;
  // border: 1px solid #ccc;
  padding: 20px 10px;
  box-shadow: 1px 1px 7px 0px #cecece;
}
.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.login-form .head {
  justify-content: center;
}
.login-form h1 {
  margin-bottom: 1rem;
  text-align: center;
}
.login-form .alert {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.login-form .form-input {
  margin-bottom: 1rem;
}
.btn-submit {
  cursor: pointer;
}
.no-account-wrapper {
  margin-top: 1.5rem;
}
</style>
