<template>
  <span class="badge">
      5
  </span>
</template>

<script>
export default {

}
</script>

<style>
.badge {
     
    padding: 1px 3px 6%;
    color: #fff;
    font-weight: 400;
    font-size: 10px;
    white-space: pre-wrap;
    text-align: start;
    background: rgba(10, 180, 191, 1);
    border-radius: 100%;
    position: relative;
    bottom: 17px;
    right: 13px;

}
</style>