<template>
  <div class="switch-container">
    <slot name="before"></slot>
    <label class="switch" :class="getSwitchClasses()">
      <input type="checkbox" v-model="checkedV" />

      <span class="slider round">
        <span class="label"> </span>
      </span>
    </label>

    <span class="label">
      <slot name="default"> {{ checkedV ? "Вкл" : "Выкл" }} </slot>
    </span>
    <slot name="after"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkedV: false,
    };
  },
  props: {
    checked: Boolean,
    design: {
      type: String,
    },
  },
  methods: {
    getSwitchClasses() {
      return this.design.split(" ").map((s) => "design-" + s);
    },
  },
  computed: {},
  mounted() {
    this.checkedV = this.checked;
  },
  watch: {
    checked(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checkedV = newVal;
      }
    },
    checkedV(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("update:checked", newVal);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$sliderDark: #0052f2;
$sliderDarkDot: #1f222b;
$sliderDarkInactive: #6d84ad;

.switch-container {
  display: flex;
  align-items: center;
}

.switch-container .label {
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 140%;
  min-width: 4em;
  color: #2d4350;

  .dark & {
    color: #ebebeb;
  }
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
input:checked + .label {
  color: white;
  right: 0;
  left: 10px;
  line-height: 40px;

  .dark & {
    color: $sliderDarkInactive;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1.5px solid #6f809e;

  .dark & {
    border-color: $sliderDarkInactive;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #6f809e;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 20px;
  height: 20px;

  .dark & {
    background-color: $sliderDarkInactive;
  }
}

input:checked + .slider {
  background-color: #0052f2;
  border-color: #0052f2;

  .dark & {
    background-color: $sliderDark;
  }
}

input:checked + .slider:before {
  transform: translateX(25px);
  background-color: white;

  .dark & {
    background-color: $sliderDarkDot;
  }
}
.switch {
  &.design-small {
    width: 48px;
    height: 24px;

    input:checked + .slider:before {
      transform: translateX(20px);
    }
    .slider {
      &::before {
        width: 16px;
        height: 16px;
        bottom: 2.4px;
      }
    }
  }
}
</style>
