<template>
  <div class="input-container">
    <svg
      v-svg
      symbol="icon_search"
      size="0 0 18 18"
      role="presentation"
      class="input-icon"
    ></svg>
    <input
      type="text"
      :placeholder="placeholder"
      class="input-search"
      v-model="searchText"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: "",
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "Поиск",
    },
  },
  watch: {
    searchText() {
      this.$emit("input", this.searchText);
    },
  },
};
</script>

<style lang="scss">
.input-search {
  width: 100%;
  height: 40px;
  padding-left: 35px;
  color: rgba(174, 185, 189, 1);
  font-size: 14px;
  outline: none;
  border: 1px solid rgba(214, 218, 234, 1);
  border-radius: 2px;

  &:hover {
    border: 1px solid #0052f2;
  }

  &:focus-visible {
    border: 1px solid #0052f2;
    box-shadow: 0px 0px 4px rgba(0, 82, 242, 0.8);
    color: rgba(45, 67, 80, 1);
  }
}

.input-container {
  position: relative;
  width: 255px;
}

.input-icon {
  position: absolute;
  position: absolute;
  top: 10px;
  left: 10px;
  fill: #a7b1c0;
}
</style>
