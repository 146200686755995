<template>
  <textarea
    class="table-input textarea-overlay"
    :class="{ collapsed: !opened }"
    ref="textarea"
    @focus="focusIn"
    @blur="focusOut"
  >
  </textarea>
</template>

<script>
export default {
  data() {
    return {
      size: null,
      opened: false,
    };
  },
  props: {},
  methods: {
    focusIn() {
      this.opened = true;
    },
    focusOut() {
      this.opened = false;
      let el = this.$refs.textarea;
      this.size = [el.offsetWidth, el.offsetHeight];
    },
  },
};
</script>

<style lang="scss">
.textarea-overlay {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 250px;
  height: 70px;
  min-width: 180px;
  min-height: 35px;
  max-width: 500px;
  max-height: 300px;
  z-index: 5;
  // resize: none;

  &:focus-visible {
    z-index: 6;
  }

  &.collapsed {
    width: inherit !important;
    height: inherit !important;
    resize: none !important;
    transition: height, width 0.1s;
  }

  &-container {
    position: relative;
    display: block;
    min-width: 180px;
    min-height: 35px;
  }
}
</style>
