<template>
  <div
    class="dropdown-container"
    @mouseover="showingSelect = true"
    @mouseleave="showingSelect = false"
    @click="showingSelect = true"
  >
    <slot name="dropdown_value" :selected="selected">
      <div class="dropdown-value" :style="{ width: width }">
        <span>
          {{ getReadable(selected) }}
        </span>

        <div class="dropdown-arrow" :class="{}">
          <svg
            v-if="showingSelect"
            v-svg
            symbol="arrow_up"
            size="0 0 24 24"
            role="presentation"
          ></svg>
          <svg
            v-else
            v-svg
            symbol="arrow_down"
            size="0 0 24 24"
            role="presentation"
          ></svg>
        </div>
      </div>
    </slot>

    <div
      class="dropdown-select"
      :class="{ 'dropdown-hide': !showingSelect, center: centerSelect }"
    >
      <slot name="dropdown_content">
        <div
          class="dropdown-item"
          :class="{ active: isSelected(option) }"
          v-for="option of options"
          :key="option.id"
          @click="setActive(option.id)"
        >
          {{ option.name }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
      showingSelect: false,
    };
  },
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    default: {
      type: String,
      defalt: null,
    },
    width: {
      type: String,
      default: "auto",
    },
    centerSelect: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getOption(option_id) {
      return (
        this.options.filter((d) => {
          return d.id == option_id;
        })[0] || null
      );
    },
    getDefault() {
      return this.getOption(this.default) || this.options[0];
    },
    getReadable(option_id) {
      let val = this.getOption(option_id);
      return val ? val.name : this.getDefault().name;
    },
    getCurrentText() {
      return this.getReadable(this.selected);
    },
    isSelected(option) {
      if (this.selected) {
        return option.id == this.selected;
      } else {
        return option.id == this.getDefault().id;
      }
    },
    //
    setActive(option_id) {
      this.selected = option_id;
      this.$emit("selected", this.selected);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.dropdown-container {
  position: relative;
  // min-width: 45px;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: center;
  color: #5d61ae;
  svg {
    fill: #5d61ae;
  }

  .dropdown-value {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      flex-grow: 1;
      // margin-right: 5px;
    }
  }

  .dropdown-arrow {
    height: 24px;
  }

  .dropdown-select {
    position: absolute;
    top: auto;
    // left: 50%;
    // transform: translateX(-50%);
    // width: 100%;
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #d6daea;
    box-sizing: border-box;

    box-shadow: 3px 6px 15px #e0e3f2;
    border-radius: 2px;
    transition: 0.2s ease-in-out;

    &.center {
      text-align: center;
    }

    &.dropdown-hide {
      opacity: 0;
      visibility: hidden;
    }

    .dropdown-item {
      padding: 7px 12px;
      background: white;
      transition: ease-in 0.1s;
      color: #5d61ae;

      &:hover,
      &.active {
        background: #f2f3f8;
        color: #414588;
      }
    }
  }
}

.dropdown-link {
  display: block;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  margin: 15px 20px;
  &,
  &:active,
  &:visited {
    color: #2d4350;
    text-decoration: none;
  }
  &:hover {
    color: #5d61ae;
  }
}
.dropdown-delim {
  border: 1px solid #e6eaee;
  margin: 0 20px;
}

.dark {
  .dropdown-container {
    .dropdown-select {
      border: none;
      background: #252938;
      box-shadow: 3px 6px 15px rgba(27, 29, 38, 0.5);
    }

    .dropdown-item {
      background: #252938;

      &:hover,
      &.active {
        background: #2d3346;
      }
    }
  }

  .dropdown-link {
    &,
    &:active,
    &:visited {
      color: #ebebeb;
    }
    &:hover {
      color: #7c87e7;
    }
  }
  .dropdown-delim {
    border: 1px solid #32394e;
  }
}
</style>
