<template>
  <modal @close="close()" class="modal-black-upload" title="Загрузить Black Page">
    <form class="form-container" @submit.prevent="submitForm()">
      <div class="form-create">
        <div class="form-col">
          <div class="form-group">
            <label>Имя страницы</label>
            <input
              type="text"
              class="c-input"
              :class="{ 'is-invalid': formErrors && formErrors.name }"
              v-model="black.name"
              placeholder="Задайте имя страницы"
            />
          </div>
        </div>
        <div class="form-col">
          <div class="form-group">
            <label>Язык страницы</label>
            <c-dropselect
              :options="Object.entries(Languages)"
              placeholder="Выбрать язык"
            ></c-dropselect>
            <!-- <select type="text" class="c-input c-select" v-model="black.lang">
              <option
                v-for="(lang_name, lang_code) of Languages"
                :key="lang_code"
                :value="lang_code"
              >
                {{ lang_name }}
              </option>
            </select> -->
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Загрузить ZIP архив или папку целиком</label>
        <drop-zone
          @update="dropUpdate"
          :uploadProgress="uploadProgress"
          :class="{ 'is-invalid': formErrors && formErrors.attachment }"
        ></drop-zone>
      </div>

      <div class="form-submit flex flex-center">
        <button type="button" @click="close()" class="btn button button-transparent">
          Отменить
        </button>
        <button
          type="submit"
          class="btn button button-primary large"
          :class="{ disabled: isSaving }"
        >
          <div class="loading-spinner white wtext" v-if="isSaving" />
          <template>
            <span v-if="black.id">Сохранить</span>
            <span v-else> Сохранить </span>
          </template>
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DropZone from "@/components/Dropzone.vue";
import CDropselect from "@/components/Form/с-dropselect.vue";

let Languages = {
  ru: "Русский",
};

let defaultBlack = {
  id: null,
  name: "",
  lang: "ru",
  attachment: null,
};

export default {
  components: { Modal, DropZone, CDropselect },
  data() {
    return {
      formErrors: null,
      black: {},
      isSaving: false,
      savingResult: null,
      uploadProgress: null,
      Languages: Languages,
    };
  },
  mounted() {
    this.resetForm();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formValidate() {
      let errors = {};
      let black = this.black;
      if (!black.name) {
        errors.name = "Обязательное поле";
      }
      if (!black.attachment) {
        errors.attachment = "Обязательное поле";
      }

      //
      this.formErrors = errors;
      return Object.getOwnPropertyNames(errors).length - 1 < 1; // -1 because observator
    },
    resetForm() {
      Object.assign(this.black, defaultBlack);
    },
    submitForm() {
      if (!this.formValidate()) {
        return;
      }

      this.isSaving = true;
      let formData = new FormData();
      formData.append("title", this.black.name);
      formData.append("language", this.black.lang);
      formData.append("attachment", this.black.attachment);

      console.debug("Creating black-page with formData: ", formData);

      this.$apiClient
        .updateBlackpage(null, formData, {
          onUploadProgress: (event) => {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            console.debug("File upload progress: ", percentCompleted, "%");
            this.uploadProgress = percentCompleted;
          },
        })
        .then((resp) => {
          this.isSaving = false;
          this.savingResult = true;
          this.percentCompleted = null;
          this.close();
          this.resetForm();
          this.$emit("update");
        })
        .catch((err) => {
          this.isSaving = false;
          this.savingResult = false;
          this.percentCompleted = null;
        });
    },
    dropUpdate(val) {
      this.black.attachment = val;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal {
  height: auto;
  min-height: 670px;
}

.modal-black-upload::v-deep .modal-container {
  max-width: 660px;
}
.modal-black-upload::v-deep .modal-header {
  margin-bottom: 40px;
  padding-left: 10px;
}
.form-create::v-deep .c-select {
  width: 250px;
}
.form-create {
  // margin-top: 3rem;
  margin-bottom: 40px - 24px; // -24px
  .c-input {
    width: 250px;
  }
}
form {
  label {
    margin-bottom: 0.7rem;
  }
}
</style>
