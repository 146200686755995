<template>
  <div>
    <div
      class="drop-container"
      :class="[{ 'drop-active': dropActive }, 'design-' + design]"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
      @dragenter.prevent
      @dragover.prevent
    >
      <input type="file" class="hidden" ref="file" hidden @change="fileChange" />

      <div class="drop-progress" v-if="uploadProgress">
        <vue-ellipse-progress :progress="uploadProgress" :size="170" />
      </div>

      <div class="drop-info" v-else-if="file">
        Файл выбран: <span class="filename">{{ file.name }}</span>
      </div>
      <div class="drop-hints" v-else>
        <svg
          width="30"
          height="30"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H19C19.2652 18 19.5196 17.8946 19.7071 17.7071C19.8946 17.5196 20 17.2652 20 17V6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5H10C9.66565 5 9.35342 4.8329 9.16795 4.5547L7.46482 2H3ZM0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H8C8.33435 0 8.64658 0.167101 8.83205 0.4453L10.5352 3H19C19.7957 3 20.5587 3.31607 21.1213 3.87868C21.6839 4.44129 22 5.20435 22 6V17C22 17.7957 21.6839 18.5587 21.1213 19.1213C20.5587 19.6839 19.7957 20 19 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.31607 18.5587 0 17.7957 0 17V3C0 2.20435 0.31607 1.44129 0.87868 0.87868Z"
            fill="#A7B1C0"
          />
        </svg>

        <span>
          Перетащите файлы в окно <br />
          или
          <a href="#" @click.prevent="openFileSelect()">Выберите на компьютере</a>
        </span>
      </div>
    </div>
    <span
      :class="uploadProgress >= 100 ? 'text-success' : 'text-primary'"
      v-if="uploadProgress"
    >
      Загрузка {{ uploadProgress.toFixed() }}%
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import VueEllipseProgress from "vue-ellipse-progress";

Vue.use(VueEllipseProgress);

console.debug(VueEllipseProgress);

export default {
  // components: { "vue-ellipse-progress": VueEllipseProgress },
  data() {
    return {
      file: null,
      dropActive: false,
      // uploadProgress: 50,
    };
  },
  props: {
    uploadProgress: {},
    design: {},
  },
  methods: {
    openFileSelect() {
      this.$refs.file.click();
    },
    fileChange() {
      this.file = this.$refs.file.files[0];
      this.$emit("update", this.file);
    },
    dragover() {
      this.file = null;
      this.dropActive = true;
    },
    dragleave() {
      this.dropActive = false;
    },
    drop(event) {
      event.preventDefault();

      console.debug("DropZone drop event:", event);
      this.$refs.file.files = event.dataTransfer.files;
      this.fileChange();
    },
  },
};
</script>

<style lang="scss" scoped>
$themeColor: #3035a9;

.hidden {
  display: none;
  width: 0;
  height: 0;
}

.drop-container {
  width: 100%;
  min-height: 200px;
  border: 1px dashed #6f809e;
  display: flex;
  flex: 1 0;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  .is-invalid & {
    border-color: rgb(209, 30, 30);
  }

  .filename {
    color: $themeColor;
    text-decoration: underline;
  }

  &.drop-active {
    border-color: $themeColor;
  }

  .drop-hints {
    font-size: 14px;
    line-height: 140%;
    color: #a7b1c0;
    font-family: Lato;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 1rem;
    }
  }

  a {
    color: $themeColor !important;
    text-decoration: none;

    .dark & {
      color: #7c87e7 !important;
    }
  }

  &.design-small {
    min-height: 100px;
  }
}

.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
