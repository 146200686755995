<template>
  <div class="question-container">
    <h3 class="question-title" @click="showToggle()" :class="{ active: showContent }">
      <slot name="title" />
    </h3>

    <div class="question-content" :class="{ hide: !showContent }">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "faq-item",
  data() {
    return {
      showContent: false,
    };
  },
  methods: {
    showToggle() {
      this.showContent = !this.showContent;
    },
  },
};
</script>

<style lang="scss" scoped>
.question-container {
  border-top: 1px solid #d6daea;

  .question-title {
    margin: 1.2rem 0;
    color: #5d61ae;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.active,
    &:hover {
      color: #414588;
    }
  }

  .question-content {
    transition: 0.2s ease;
    transform: none;
    margin-bottom: 2rem;

    &.hide {
      display: none;
      width: 0;
      height: 0;
      // transform: scaleY(0) translateY(-100%);
      opacity: 0;
      margin: 0;
    }
  }
}
</style>
