<template>
  <div id="app">
    <c-menu :active="active" @changeActive="select" v-if="isLoggedIn"> </c-menu>
    <section class="main">
      <c-header :userProfile="userProfile" :userTariff="userTariff">
        <!-- if="isLoggedIn" -->
      </c-header>
      <main class="c-content" v-if="userProfile !== null">
        <router-view />
      </main>

      <div class="app-loading flex flex-center" v-else>
        <div class="loading-spinner mr"></div>
        Загрузка...
      </div>
    </section>
  </div>
</template>

<script>
import cMenu from "@/components/Menu/c-menu.vue";
import CHeader from "./components/Header/c-header.vue";
import { getAccessToken } from "@/api/localstorage.js";

function saveMenu(val) {
  localStorage.setItem("menuItem", val);
}
function getMenu() {
  return localStorage.getItem("menuItem");
}

export default {
  components: { cMenu, CHeader },
  data() {
    return {
      active: getMenu() || "2",
      darkMode: null,
      userProfile: null,
      userTariff: null,
    };
  },
  mounted() {
    // this.$router.push("/app/campaigns").catch(() => {});
    if (this.userProfile == null) {
      console.debug("Mounted, checking user profile...");
      this.getUserProfile();
    }
    let _self = this;
    this.$apiClient.axios.interceptors.response.use(undefined, function (error) {
      const statusCode = error.response ? error.response.status : null;
      console.debug("Request err:", statusCode, error, error.response, _self.$route.path);

      if (
        statusCode == 401 &&
        error.response &&
        error.response.url !== "/profile" &&
        _self.$route.path !== "/app/login"
      ) {
        console.info("Received unathorized error!");
        _self.gotoLogin();
      }
      return Promise.reject(error);
    });
  },
  methods: {
    select(item) {
      this.active = item;
      saveMenu(item);
    },
    getUserProfile(force = 1) {
      this.$apiClient.params.access_token = getAccessToken();
      this.$apiClient.updateAuthHeaders();
      if (this.userProfile == false && !force) {
        console.debug("Rejected check");
        return;
      }
      this.userProfile = null;
      this.userTariff = null;
      console.debug("Checking login status...", this.$apiClient.params, getAccessToken());

      this.$apiClient
        .getUserProfile()
        .then((resp) => {
          this.userProfile = resp.data;
          if (
            this.$route.path == "/" ||
            this.$route.path == "/app/login" ||
            this.$route.path == "/app/register"
          ) {
            this.$router.push("/app/campaigns").catch(() => {});
          }
          // Get user tariff
          this.$apiClient.getUserTariff().then((resp) => {
            this.userTariff = resp.data;
          });
        })
        .catch((err) => {
          this.userProfile = false;
          if (this.$route.path !== "/app/login" && this.$route.path !== "/app/register") {
            this.gotoLogin();
          }
        });
    },
    gotoLogin() {
      if (this.$route.path !== "/app/login") {
        this.$router.push("/app/login").catch(() => {});
      }
    },
  },
  computed: {
    isLoggedIn() {
      return Boolean(this.userProfile);
    },
  },
};
</script>

<style lang="scss">
body {
  background: rgba(242, 243, 248, 1);
  display: flex;
  flex-direction: row;
}

.c-content {
  display: flex;
  flex-direction: column;
}

.app-loading {
  height: 100%;
  width: 100%;

  .loading-spinner {
    &.mr {
      margin-right: 1rem;
    }
  }
}
</style>
