<template>
  <div class="tooltip-box">
    <slot>
      <svg
        v-svg
        symbol="icon_help_circle_stroke"
        size="0 0 16 16"
        role="presentation"
        class="icon-tooltip-help"
      ></svg>
    </slot>
    <div class="tooltip">
      <span class="text">
        <slot name="content">
          {{ text }}
        </slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Tooltip",
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-box {
  position: relative;
  display: inline-block;
}

.tooltip-box:hover .tooltip {
  opacity: 1;
}

.tooltip {
  color: #ffffff;
  text-align: center;
  padding: 10px 10px 12px;
  border-radius: 6px;

  min-width: 50px;
  max-width: 200px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;

  opacity: 0;
  transition: opacity 0.5s ease-out;

  position: absolute;
  z-index: 1;

  background: rgba(59, 56, 72, 1);
  box-shadow: 0px 9px 12px rgba(18, 15, 32, 0.1);
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.2px;
  color: #d4d3d9;
}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(59, 56, 72, 1) transparent transparent transparent;
}

.icon-tooltip-help {
  fill: #a7b1c0;
}
</style>
