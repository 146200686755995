<template>
  <modal @close="close()" title="Загрузить White Page">
    <form class="form-container" @submit.prevent="submitForm()">
      <div class="form-create">
        <div class="form-col">
          <div class="form-group">
            <label>Имя страницы</label>
            <input
              type="text"
              class="c-input"
              :class="{ 'is-invalid': formErrors && formErrors.name }"
              v-model="white.name"
              placeholder="Задайте имя страницы"
            />
          </div>
        </div>
        <div class="form-col">
          <div class="form-group">
            <label>Язык страницы</label>
            <select type="text" class="c-input c-select" v-model="white.lang">
              <option
                :value="lang_code"
                v-for="(lang_name, lang_code) of Languages"
                :key="lang_code"
              >
                {{ lang_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Загрузить ZIP архив или папку целиком</label>
        <drop-zone
          @update="dropUpdate"
          :uploadProgress="uploadProgress"
          :class="{ 'is-invalid': formErrors && formErrors.attachment }"
        ></drop-zone>
      </div>

      <div class="form-submit flex flex-center">
        <button type="button" @click="close()" class="btn button button-transparent">
          Отменить
        </button>
        <button
          type="submit"
          class="btn button button-primary large"
          :class="{ disabled: isSaving }"
        >
          <div class="loading-spinner white wtext" v-if="isSaving" />
          <template>
            <span v-if="white.id">Сохранить</span>
            <span v-else> Сохранить </span>
          </template>
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DropZone from "@/components/Dropzone.vue";

let Languages = {
  ru: "Русский",
};

export default {
  components: { Modal, DropZone },
  data() {
    return {
      formErrors: null,
      white: {
        id: null,
        name: "",
        lang: "ru",
        attachment: null,
      },
      isSaving: false,
      savingResult: null,
      uploadProgress: null,
      Languages: Languages,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formValidate() {
      let errors = {};
      let white = this.white;
      if (!white.name) {
        errors.name = "Обязательное поле";
      }
      if (!white.attachment) {
        errors.attachment = "Обязательное поле";
      }

      //
      this.formErrors = errors;
      return Object.getOwnPropertyNames(errors).length - 1 < 1; // -1 because observator
    },
    submitForm() {
      if (!this.formValidate()) {
        return;
      }

      this.isSaving = true;
      let formData = new FormData();
      for (const [key, val] of Object.entries(this.white)) {
        formData.append(key, val);
      }
      console.debug("Creating white-page with formData: ", formData);

      this.$apiClient
        .updateWhitepage(null, formData, {
          onUploadProgress: (progressEvent) =>
            (this.uploadProgress = progressEvent.loaoded),
        })
        .then((resp) => {
          this.isSaving = false;
          this.savingResult = true;
        })
        .catch((err) => {
          this.isSaving = false;
          this.savingResult = false;
        });
    },
    dropUpdate(val) {
      this.white.attachment = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-create {
  margin-top: 3rem;
}
form {
  label {
    margin-bottom: 0.7rem;
  }
}
</style>
