<template>
  <div class="container">
    <div class="head">
      <div class="actions-head-left">
        <h1>White Pages</h1>
      </div>
      <div class="actions-head-right">
        <c-button type="ghost" size="large" icon="icon_link" @click="openModalGenerate()">
          Сгенерировать
        </c-button>
        <c-button
          type="ghost"
          style="margin-left: 30px"
          size="large"
          icon="icon_download"
          click="openModalUpload()"
        >
          Загрузить
        </c-button>
      </div>
    </div>

    <c-table
      :columns="columns"
      :dataSrc="dataSrc"
      cheked
      :dataPerPage="defaultRows"
      id="white"
      attrName="name"
      v-if="dataSrc"
    >
      <span slot="name" class="name">
        <span> Название страницы </span>
        <span class="subname"> id </span>
      </span>

      <span slot="names" slot-scope="record">
        <div class="campaign-name">
          <span class="cname"> {{ record["record"]["name"] }}</span>
          <span class="csubname"> {{ record["record"]["subname"] }}</span>
        </div>
      </span>

      <span slot="total">
        <svg
          v-svg
          symbol="icon_users"
          size="0 0 20 20"
          role="presentation"
          fill="red"
          class="c-icons users"
        ></svg>
      </span>

      <span slot="action" slot-scope="record">
        <div class="action" style="display: flex; flex-direction: row">
          <span
            @click="action('Stat', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_external-link"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
          <span
            @click="action('Copy', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_refresh"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
          <span
            @click="action('Edit', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_edit"
              size="0 0 20 20"
              role="presentation"
              class="c-icons edit"
            ></svg>
          </span>
          <span
            @click="action('Download', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_download"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
          <span
            @click="action('Delete', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_trash_2"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
          <span class="handle" style="margin-left: 20px; margin-top: 10px">
            <svg
              v-svg
              symbol="icon_drag_and_drop"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
        </div>
      </span>
    </c-table>

    <loading-indicator class="loading-spaced" v-else></loading-indicator>

    <white-page-upload
      v-show="modalUploadVisible"
      @close="closeModalUpload()"
    ></white-page-upload>

    <white-page-generate
      v-show="modalGenerateVisible"
      @close="closeModalGenerate()"
      @update="loadInfo()"
    ></white-page-generate>
  </div>
</template>

<script>
const columns = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    width: "100",
  },
  {
    dataIndex: "name",
    key: "name",
    slots: { title: "name" },
    width: "190",
    scopedSlots: { customRender: "names" },
  },
  {
    dataIndex: "language",
    key: "language",
    width: "200",
    title: "Язык",
  },
  {
    dataIndex: "clicks",
    key: "clicks",
    width: "200",
    slots: { title: "total" },
  },
  {
    dataIndex: "date",
    key: "date",
    width: "200",
    title: "Дата",
  },
  {
    title: "Действия",
    key: "action",
    width: "260",
    scopedSlots: { customRender: "action" },
  },
];

const dataSrc = [
  {
    id: 1,
    name: "IT_CBD-Gummies",
    subname: "cbdj536",
    clicks: 0,
    language: "EN",
    date: "2015-06-21",
  },
];
import CSwitch from "../Form/c-switch.vue";
import CTable from "../Table/c-table.vue";
import CButton from "../Button/c-button.vue";
import WhitePageUpload from "./WhitePageUpload.vue";
import WhitePageGenerate from "./WhitePageGenerate.vue";
import loadingIndicator from "@/components/Loading/loading-indicator.vue";

export default {
  data() {
    return {
      columns,
      defaultRows: 5,
      dataSrc: null,
      modalUploadVisible: false,
      modalGenerateVisible: false,
    };
  },
  components: {
    CButton,
    CTable,
    CSwitch,
    WhitePageUpload,
    WhitePageGenerate,
    loadingIndicator,
  },
  mounted() {
    this.loadInfo();
  },
  methods: {
    loadInfo() {
      this.$apiClient.getWhitepages().then((resp) => {
        this.dataSrc = resp.data.data;
      });
    },
    openModalUpload() {
      this.modalUploadVisible = true;
    },
    closeModalUpload() {
      this.modalUploadVisible = false;
    },
    openModalGenerate() {
      this.modalGenerateVisible = true;
    },
    closeModalGenerate() {
      this.modalGenerateVisible = false;
    },
    action(todo, id) {
      console.log(todo + "-" + id);
      if (!id) {
        return;
      }
      const item = this.dataSrc.find((items) => items.id == id);

      if (todo == "Delete") {
        const isSure = confirm(
          `Вы уверены что хотите удалить White page '${item.name}'?`
        );
        if (isSure) {
          this.$apiClient.deleteWhitepage(item.id).then((resp) => {
            this.loadInfo();
          });
        }
      }
    },
  },
};
</script>

<style></style>
