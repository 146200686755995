<template>
  <div class="header">
    <div class="plan">
      <span v-if="userProfile"> Тариф {{ tariffName }} </span>
    </div>
    <ul class="navbar">
      <li class="navbar-item"><c-switch :checked.sync="darkMode"> </c-switch></li>
      <li class="navbar-item">
        <!-- <c-select> </c-select> -->
        <c-dropdown
          :options="[
            { id: 'ru', name: 'RU' },
            { id: 'en', name: 'EN' },
            { id: 'es', name: 'ES' },
          ]"
          default="ru"
          :centerSelect="true"
        />
      </li>
      <li class="navbar-item">
        <template v-if="userProfile">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.05033 3.05025C8.36309 1.7375 10.1436 1 12.0001 1C13.8566 1 15.6371 1.7375 16.9498 3.05025C18.2626 4.36301 19.0001 6.14349 19.0001 8C19.0001 11.3527 19.7171 13.4346 20.378 14.6461C20.7098 15.2544 21.0329 15.6535 21.2573 15.8904C21.3698 16.0091 21.4581 16.0878 21.5114 16.1322C21.538 16.1544 21.5558 16.168 21.5635 16.1737C21.5647 16.1746 21.5657 16.1753 21.5664 16.1758C21.9249 16.4221 22.0835 16.8725 21.9572 17.2898C21.8295 17.7115 21.4407 18 21.0001 18H3.00008C2.55941 18 2.17068 17.7115 2.04299 17.2898C1.91664 16.8725 2.07528 16.4221 2.43377 16.1758C2.43447 16.1753 2.43542 16.1746 2.43663 16.1737C2.44432 16.168 2.46218 16.1544 2.4888 16.1322C2.54202 16.0878 2.6304 16.0091 2.74288 15.8904C2.9673 15.6535 3.29039 15.2544 3.62218 14.6461C4.28301 13.4346 5.00008 11.3527 5.00008 8C5.00008 6.14348 5.73758 4.36301 7.05033 3.05025ZM2.44388 16.169C2.44395 16.1689 2.44403 16.1688 2.44411 16.1688C2.44411 16.1688 2.4441 16.1688 2.4441 16.1688L2.44388 16.169ZM5.1494 16H18.8508C18.7747 15.8753 18.6983 15.7434 18.6222 15.6039C17.783 14.0654 17.0001 11.6473 17.0001 8C17.0001 6.67392 16.4733 5.40215 15.5356 4.46447C14.5979 3.52678 13.3262 3 12.0001 3C10.674 3 9.40223 3.52678 8.46454 4.46447C7.52686 5.40215 7.00008 6.67392 7.00008 8C7.00008 11.6473 6.21715 14.0654 5.37797 15.6039C5.30188 15.7434 5.22549 15.8753 5.1494 16Z"
              fill="#6F809E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.76829 20.1352C10.246 19.858 10.8579 20.0207 11.1351 20.4984C11.223 20.6499 11.3491 20.7757 11.5009 20.8632C11.6528 20.9506 11.8249 20.9966 12.0001 20.9966C12.1753 20.9966 12.3474 20.9506 12.4992 20.8632C12.651 20.7757 12.7772 20.6499 12.8651 20.4984C13.1422 20.0207 13.7541 19.858 14.2318 20.1352C14.7096 20.4123 14.8722 21.0242 14.5951 21.5019C14.3313 21.9566 13.9528 22.3339 13.4974 22.5962C13.042 22.8586 12.5256 22.9966 12.0001 22.9966C11.4745 22.9966 10.9581 22.8586 10.5027 22.5962C10.0473 22.3339 9.66878 21.9566 9.40506 21.5019C9.12794 21.0242 9.29056 20.4123 9.76829 20.1352Z"
              fill="#6F809E"
            />
          </svg>
          <c-badge> </c-badge>
        </template>
      </li>

      <c-dropdown>
        <template #dropdown_value>
          <div class="flex flex-center">
            <li class="navbar-item user">
              <span v-if="userProfile">
                {{ userProfile.name }}
              </span>
            </li>

            <li class="navbar-item">
              <div class="avatar" v-if="userProfile">
                <img src="https://www.thispersondoesnotexist.com/image?11628953216" />
              </div>
            </li>
          </div>
        </template>

        <template #dropdown_content>
          <a class="dropdown-link" href="#" @click.prevent="goSettings()"
            >Настройки аккаунта</a
          >

          <div class="dropdown-delim"></div>

          <a class="dropdown-link" href="#" @click.prevent="accountLogout()">Выйти</a>
        </template>
      </c-dropdown>
    </ul>
  </div>
</template>

<script>
import CSelect from "../Form/c-select.vue";
import CSwitch from "../Form/c-switch.vue";
import CBadge from "../Badge/c-badge.vue";
import CDropdown from "../c-dropdown.vue";
import { setAccessToken } from "@/api/localstorage.js";

function getSystemDarkMode() {
  try {
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    return prefersDarkScheme.matches;
  } catch (err) {
    return false;
  }
}

function getDarkTheme() {
  let darkMode = localStorage.getItem("darkMode");
  return Boolean(darkMode !== null ? darkMode == "true" : getSystemDarkMode());
}

function setDarkTheme(val) {
  localStorage.setItem("darkMode", val);
}

export default {
  props: {
    userProfile: {}, // Can be null or false
    userTariff: {}, // Can be null or false
  },

  components: { CSelect, CSwitch, CBadge, CDropdown },
  data() {
    return {
      darkMode: null,
    };
  },
  created() {
    this.darkMode = getDarkTheme();
  },
  methods: {
    goSettings() {
      this.$router.push("/app/settings").catch(() => {});
    },
    accountLogout() {
      let isConfirmed = confirm("Вы уверены что хотите выйти из аккаунта?");
      if (isConfirmed) {
        setAccessToken("");
        this.$router.push("/app/login").catch(() => {});
        this.$parent.getUserProfile();
      }
    },
  },
  computed: {
    tariffName() {
      if (this.userTariff && this.userTariff.name) {
        return this.userTariff.name;
      } else {
        return "не подключен";
      }
    },
  },
  watch: {
    darkMode(newVal, oldVal) {
      // Dark theme toggler

      console.debug("Dark theme switched:", newVal);
      this.$parent.darkMode = newVal;
      this.$parent.$parent.$darkMode = newVal;

      setDarkTheme(newVal);
      var root = document.getElementsByTagName("html")[0];

      if (newVal) {
        root.classList.add("dark");
      } else {
        root.classList.remove("dark");
      }
    },
  },
};
</script>

<style>
.header {
  display: flex;
  flex-direction: row;
  height: 84px;
  min-height: 84px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(214, 218, 234, 1);
  /*  */
  position: sticky;
  top: 0;
  z-index: 90;
}

.navbar-item.user {
  margin-right: -20px;
}

.header li {
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  color: rgba(96, 111, 137, 1);
  font-size: 16px;
  font-weight: 700;
}
.plan {
  color: rgba(174, 185, 189, 1);
  font-size: 16px;
  font-weight: 700;
}

.avatar {
  width: 38px;
  height: 38px;
  border: 2px solid;
  border-radius: 40px;
}

.navbar {
  display: flex;
  align-items: center;
}

.user {
  align-items: center;
}

.avatar img {
  width: 100%;
  border-radius: 100px;

  border: 1px solid white;
}

.user-name {
  color: rgba(96, 111, 137, 1);
  font-size: 16px;
  font-weight: 700;
}
</style>
