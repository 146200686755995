<template>
  <div class="filter-clicks-container">
     <h1> В разработке </h1>

  </div>
</template>

<script>
const column = [
    {
    title: "Кампания",
    dataIndex: "campaign",
    key: "campaign",
    width: "100",
  },
   {
    title: "Всего кликов",
    dataIndex: "total",
    key: "total",
    width: "100",
  },
  {
    title: "Фильтроанных кликов",
    dataIndex: "filter",
    key: "filter",
    width: "100",
  },
   {
    title: "Процент фильтрации",
    dataIndex: "filter_percent",
    key: "filter_percent",
    width: "100",
  },
]

const dataSrc = [
    {"campaign": "Test", "total": 200, "filter": 90, "filter_percent": 20}
]
import cTable from '../Table/c-table.vue'
import CDropselect from "../Form/с-dropselect.vue";

export default {
    data() {
        return {column, dataSrc}
    },
  components: { cTable, CDropselect},

}
</script>

<style lang="scss">

.filter-clicks-container {
    display: flex;
    flex-direction: column;
}


.campaigns{
    display: flex;
    flex-direction: row;
}
</style>