<template>
<div class="tabs">
  <ul class="nav-tabs">
      
      <li v-for="(tab, index) in tabs"  :key="index"  class="nav-item" @click="makeActive(tab)"  :class="{'active': tab.active}">
        {{tab.label}}
      </li>
     
  </ul>
  <slot> </slot>

</div>

</template>

<script>
export default {
    created(){
        this.tabs = this.$children;
    },
    data() {
        return {  
            tabs: [],
        }
    },
    methods: {
        makeActive(tab) {
            this.tabs.forEach(function(t) {
                t.active = t === tab;
            })
        }
    }



}
</script>

<style>

* ul {
    list-style: none;
    padding: 0;
}

.nav-tabs {
    margin-top: 10px;
    width: 450px;
    user-select: none;
    display: flex;
    font-size: 1rem;
    overflow: hidden;
    border-bottom: 0.5px solid #D6DAEA;
}

.nav-item {
    padding: 10px;
}

li.nav-item {
    color: rgba(174, 185, 189, 1);
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

}

.nav-tabs li.active  {
    border-bottom: 2px solid #5D61AE;
    color: #5D61AE;
}

</style>