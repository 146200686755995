<template>
  <div class="nav-tab-panel" :class="{'active': active}">
      <slot></slot>
  </div>
</template>

<script>
export default {
    props: ["active", "label"],
    data(){
        return {
            
        }
    }

}
</script>

<style>

.nav-tab-panel {
    display:  none;
    margin-top: 20px;

   
}
.nav-tab-panel.active {
    display: block;
}

</style>