<template>
  <div class="tab-content" :class="{ active: activeTab == num }" @click="tabActivate()">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    activeTab: Number,
    num: Number,
  },
  methods: {
    tabActivate() {
      this.$emit("update:activeTab", this.num);
    },
  },
};
</script>

<style lang="scss" scoped>
$themeColor: #5d61ae;
$inactiveColor: #606f89;
$activeColor: $themeColor;
$disabledColor: #d6daea;
$padX: 10;

.tab-content {
  color: $inactiveColor;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  position: relative;
  padding: 0 $padX * 2px;
  min-width: 200px;
  text-align: center;
  user-select: none;
  cursor: pointer;

  &:hover,
  &.active {
    color: $activeColor;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -10px;
    border-bottom: 2px solid $disabledColor;
    left: -$padX + px;
    right: -$padX + px;
  }
  &.active:after {
    border-color: $themeColor;
    z-index: 2;
  }
}
</style>
