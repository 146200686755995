<template>
  <div class="container register-form-container flex-column">
    <form class="register-form" @submit.prevent="submitRegister()">
      <div class="head">
        <h1 class="text-center">Регистрация</h1>
      </div>

      <div class="alert alert-danger text-center" v-if="formRegisterError">
        {{ formRegisterError }}
        <!-- Email или имя пользователя уже используются -->
      </div>

      <div class="form-input">
        <c-input
          placeholder="Имя пользователя"
          :value="formUsername"
          @input="formUsername = $event"
          :cls="fieldCls('username')"
        ></c-input>
      </div>
      <div class="form-input">
        <c-input
          placeholder="Email"
          :value="formEmail"
          @input="formEmail = $event"
          :cls="fieldCls('email')"
        ></c-input>
      </div>
      <div class="form-input">
        <c-input
          placeholder="Пароль"
          :type="showPassword ? 'text' : 'password'"
          :value="formPassword"
          @input="formPassword = $event"
          :cls="fieldCls('password')"
        ></c-input>
      </div>
      <div class="form-input">
        <c-input
          placeholder="Повторите пароль"
          :type="showPassword ? 'text' : 'password'"
          :value="formPassword2"
          @input="formPassword2 = $event"
          :cls="fieldCls('password2')"
        ></c-input>
      </div>

      <div class="form-group">
        <label class="c-check">
          <input type="checkbox" v-model="rememberEmail" />
          <div class="checkmark"></div>
          Запомнить Email
        </label>
      </div>

      <div class="text-center">
        <button class="btn btn-submit" type="submit" :disabled="isLoading">
          <div class="loading-spinner spinner-submit" v-if="isLoading"></div>
          Войти
        </button>
      </div>
    </form>

    <div class="no-account-wrapper">
      <h5>
        Уже есть аккаунт?
        <a href="#" @click.prevent="routeRegister()">Войти</a>
      </h5>
    </div>
  </div>
</template>

<script>
import CInput from "../Form/c-input.vue";
import ApiClient from "@/api/api_client.js";
import { setAccessToken, getAccessToken } from "@/api/localstorage.js";

export default {
  data() {
    let lastEmail = localStorage.getItem("lastEmail");
    let rememberEmail = localStorage.getItem("rememberEmail");
    return {
      formEmail: lastEmail,
      formPassword: null,
      formPassword2: null,
      formUsername: null,
      showPassword: false,
      formRegisterError: false,
      formErrors: {},
      rememberEmail: rememberEmail,
      lastEmail: lastEmail,
      isLoading: false,
    };
  },

  components: {
    CInput,
  },

  created() {
    // if (getAccessToken()) {
    //   this.onRegistered();
    // }
  },

  methods: {
    validateForm() {
      let errors = {};
      if (!this.formUsername) {
        errors.username = 1;
      }
      if (!this.formEmail) {
        errors.email = 1;
      }
      if (!this.formPassword) {
        errors.password = 1;
      }
      if (!this.formPassword2) {
        errors.password2 = 1;
      } else if (this.formPassword != this.formPassword2) {
        errors.password = 1;
        errors.password2 = 1;
      }
      this.formErrors = errors;
      return Object.getOwnPropertyNames(errors).length - 1 < 1; // -1 because observator
    },
    fieldCls(field) {
      if (this.formErrors.hasOwnProperty(field) && this.formErrors[field]) {
        return "is-invalid";
      }
      return "";
    },
    submitRegister() {
      if (!this.validateForm()) {
        return;
      }
      console.log("Submit register.");
      this.isLoading = true;
      this.formRegisterError = false;
      this.$apiClient
        .userRegister(this.formUsername, this.formEmail, this.formPassword)
        .then((resp) => {
          console.log("Registered:", resp);
          this.isLoading = false;
          if (!resp) {
            return;
          }

          let token = resp.data.access_token;
          // console.debug("Setting session token:", token);
          setAccessToken(token);

          this.onRegistered();
        })
        .catch((err) => {
          this.isLoading = false;
          console.warn("Register error: ", err);

          if (!err.response.data) {
            return;
          }

          let errs = err.response.data.error.errors;
          if (errs) {
            this.formRegisterError = "";
            for (const [key, val] of Object.entries(errs)) {
              this.formRegisterError += `${key}: ${val}\n`;
            }
          } else {
            this.formRegisterError = "Ошибка регистрации";
          }
        });
    },

    routeRegister() {
      this.$router.push("/app/register").catch(() => {});
    },

    onRegistered() {
      let main_comp = this.$parent;
      main_comp.getUserProfile(true); // Update main component user profile
      // this.$router.push("/app/campaigns").catch(() => {});
    },
    updateRememberEmail() {
      localStorage.setItem("rememberEmail", this.rememberEmail ? "yes" : "");
      if (this.rememberEmail) {
        localStorage.setItem("lastEmail", this.formEmail || "");
      } else {
        localStorage.removeItem("lastEmail");
      }
    },
  },
  watch: {
    formEmail(newVal) {
      this.updateRememberEmail();
    },
    rememberEmail(newVal) {
      this.updateRememberEmail();
    },
  },
};
</script>

<style lang="scss">
.register-form {
  max-width: 320px;
  margin: 0 auto;
  // border: 1px solid #ccc;
  padding: 20px 10px;
  box-shadow: 1px 1px 7px 0px #cecece;
}
.register-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.register-form .head {
  justify-content: center;
}
.register-form h1 {
  margin-bottom: 1rem;
  text-align: center;
}
.register-form .alert {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.register-form .form-input {
  margin-bottom: 1rem;
}
.btn-submit {
  cursor: pointer;
}
.no-account-wrapper {
  margin-top: 1.5rem;
}
</style>
