var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-stat"},[_c('div',{staticClass:"total-container"},[_vm._m(0),_c('div',{staticClass:"chart"},[_c('div',{staticClass:"stat"},[_c('TrendChart',{attrs:{"datasets":[
            {
              data: [100, 324, 230, 400, 545, 957],
              smooth: false,
              fill: false,
              showPoints: true,
              className: 'total',
            },
            {
              data: [327, 235, 432, 563, 500, 876],
              smooth: false,
              fill: false,
              showPoints: true,
              className: 'black',
            },
            {
              data: [256, 321, 432, 307, 563, 901],
              smooth: false,
              fill: false,
              showPoints: true,
              className: 'white',
            } ],"interactive":true,"grid":{
            horizontalLines: true,
          },"labels":{
            xLabels: ['15.10', '16.10', '17.10', '18.10', '19.10', '20.10', '21.10'],
            yLabels: 5,
            yLabelsTextFormatter: function (val) { return Math.round(val) / 5; },
          },"showPoints":"","min":0}})],1)])]),_c('div',{staticClass:"filter"},[_c('div',{staticClass:"filter-date"},[_vm._m(1),_c('div',{staticClass:"filter-group"},[_c('c-dropselect',{staticStyle:{"width":"100%","margin":"5px"},attrs:{"options":['Item 1', 'Item 2', 'Item 3'],"placeholder":"Все кампании"}}),_c('c-datepicker',{staticStyle:{"margin":"5px"},attrs:{"range":"","lang":"ru","date-format":{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }}}),_c('button',{staticClass:"btn-main",staticStyle:{"margin-top":"20px"}},[_vm._v("Показать статистику")])],1)]),_vm._m(2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"statistic"},[_c('div',{staticClass:"clicks"},[_c('div',{staticClass:"c-title"},[_c('h2',[_vm._v("Клики")])]),_c('div',{staticClass:"clicks-data"},[_c('div',{staticClass:"total"},[_c('p',{staticClass:"count"},[_vm._v("2 331")]),_c('p',{staticClass:"label"},[_vm._v("Всего")])]),_c('div',{staticClass:"black"},[_c('p',{staticClass:"count"},[_vm._v("1 212")]),_c('p',{staticClass:"label"},[_vm._v("Black")])]),_c('div',{staticClass:"white"},[_c('p',{staticClass:"count"},[_vm._v("1 275")]),_c('p',{staticClass:"label"},[_vm._v("White")])])])]),_c('div',{staticClass:"whites"},[_c('div',{staticClass:"c-title"},[_c('h2',[_vm._v("Сгенерировано вайтов")]),_c('div',{staticClass:"whites-data"},[_c('p',[_vm._v("25/50")])]),_c('div',{staticClass:"btn-upd"},[_c('p',[_vm._v("Повысить лимит")])])])]),_c('div',{staticClass:"domains"},[_c('div',{staticClass:"c-title"},[_c('h2',[_vm._v("Домены")]),_c('div',{staticClass:"domains-data"},[_c('p',[_vm._v("10/30")])]),_c('div',{staticClass:"btn-upd"},[_c('p',[_vm._v("Повысить лимит")])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-title"},[_c('h2',[_vm._v("Кампания и период")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-method"},[_c('div',{staticClass:"c-title"},[_c('h2',[_vm._v("Метод фильтрации")]),_c('div',{staticClass:"methods-data"},[_c('div',{staticClass:"row"},[_c('p',[_vm._v("Всего кликов")]),_c('p',[_vm._v("25")])]),_c('div',{staticClass:"row"},[_c('p',[_vm._v("Отфильтровано")]),_c('p',[_vm._v("25")])]),_c('div',{staticClass:"row"},[_c('p',[_vm._v("Процент фильтрации")]),_c('p',[_vm._v("100%")])])])])])}]

export { render, staticRenderFns }