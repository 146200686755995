<template>
  <label class="switch">
    <input type="checkbox" v-model="checkedV" />

    <span class="slider round">
      <span class="label" :class="checked == true ? 'on' : 'off'">
        {{ switchMode }}
      </span>
    </span>
  </label>
</template>

<script>
export default {
  data() {
    return {
      checkedV: false,
    };
  },
  props: {
    checked: Boolean,
  },
  computed: {
    switchMode() {
      if (this.checked == true) {
        return "dark";
      } else {
        return "light";
      }
    },
  },
  mounted() {
    this.checkedV = this.checked;
  },
  watch: {
    checked(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checkedV = newVal;
      }
    },
    checkedV(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("update:checked", newVal);
      }
    },
  },
};
</script>

<style>
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.label {
  line-height: 130%;
}
.label.on {
  position: absolute;
  font-size: 12px;
  left: 10px;
  top: 5px;
  font-weight: 500;
  color: white;
}

.label.off {
  position: absolute;
  font-size: 12px;
  right: 10px;
  top: 5px;
  font-weight: 500;
  color: #5d61ae;
}
input:checked + .label {
  color: white;
  right: 0;
  left: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1.5px solid rgba(93, 97, 174, 1);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: rgba(93, 97, 174, 1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 20px;
  height: 20px;
}

input:checked + .slider {
  background-color: rgba(93, 97, 174, 1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(35px);
  -ms-transform: translateX(35px);
  transform: translateX(35px);
  background-color: white;
}
</style>
