import Vue from 'vue'
import VueRouter from 'vue-router'
import Statistic from '@/components/Modules/Statistic.vue'
import Campaigns from '@/components/Modules/Campaigns.vue'
import BlackPage from '@/components/Modules/BlackPages.vue'
import WhitePage from '@/components/Modules/WhitePages.vue'
import Domains from '@/components/Modules/Domains.vue'
import AuthLogin from '@/components/Modules/AuthLogin.vue'
import AuthRegister from '@/components/Modules/AuthRegister.vue'
import FAQ from '@/views/FAQ.vue'
import Settings from '@/views/Settings.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/app/login',
    name: 'Авторизация',
    component: AuthLogin,
  },
  {
    path: '/app/register',
    name: 'Регистрация',
    component: AuthRegister,
  },
  {
    path: '/app/statistic',
    name: 'Статистика',
    component: Statistic,
  },
  {
    path: '/app/campaigns',
    name: 'Кампании',
    component: Campaigns,
  },
  {
    path: '/app/landings/black-pages',
    name: 'Black Pages',
    component: BlackPage,
  },
  {
    path: '/app/landings/white-pages',
    name: 'White Pages',
    component: WhitePage,
  },
  {
    path: '/app/domains',
    name: 'Домены',
    component: Domains,
  },
  {
    path: '/app/faq',
    name: 'FAQ',
    component: FAQ,
  },
  {
    path: '/app/settings',
    name: 'Settings',
    component: Settings,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
