<template>
  <span @click="click()" class="c-button" :class="setClass">
    <span class="btn-text">
      <svg
        v-if="icon"
        v-svg
        :symbol="icon"
        :size="iconSize"
        role="presentation"
        class="btn-icon"
      ></svg>
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
    },
    iconSize: {
      type: String,
      default: "0 0 15 15",
    },
    size: {
      type: String,
      default: "medium",
    },
  },
  computed: {
    setClass() {
      return {
        primary: this.type === "primary",
        ghost: this.type === "ghost",
        large: this.size === "large",
        medium: this.size === "medium",
        small: this.size === "small",
        "with-icon": this.icon,
      };
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.c-button {
  display: flex;
  cursor: pointer;
  padding: 10px 14px;
  // width: 155px;
  // height: 40px;
  background: #3035a9;
  border-radius: 4px;
  color: white;
  align-items: center;
  align-content: center;

  &.large {
    // width: 184px;
    // height: 44px;
  }

  &.small {
    // width: 125px;
    // height: 34px;
    font-size: 9px;
  }
  &.small .btn-text {
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 120%;
  }

  &.ghost {
    border: 1px solid #3035a9;
    background: transparent;
  }

  &.ghost .btn-icon {
    fill: #3035a9;
  }

  &.ghost .btn-text {
    color: #3035a9;
  }
  &.ghost:hover {
    background-color: transparent;
    border: 1px solid #5452b9;

    &.btn-text {
      color: red;
    }
  }
}

.c-button:hover {
  background: #5452b9;
}

.btn-text {
  display: flex;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.2px;
  align-items: center;
  justify-content: center;
}

.btn-icon {
  margin: 3px;
  fill: white;
}
</style>
