<template>
  <div class="c-table">
    <div class="table-action">
      <div class="left-block">
        <c-dropselect
          :options="['Опция 1', 'Опция 2']"
          :placeholder="actionPlaceholder"
          @select="selectAction"
        >
        </c-dropselect>
        <span class="apply-btn" @click="apply()"> Применить </span>
        <c-dropselect
          :options="Object.values(sortOptions)"
          :value.sync="sortMode"
          placeholder="Без сортировки"
        >
        </c-dropselect>
      </div>

      <div class="right-block">
        <c-datepicker
          v-if="datepicker"
          range
          lang="ru"
          style="margin-right: 15px"
          :date-format="{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }"
          v-model="selectedDate"
        >
        </c-datepicker>
        <c-search placeholder="Поиск по кампаниям" @input="$emit('search', $event)">
        </c-search>
      </div>
    </div>

    <table class="table">
      <tr>
        <th class="checkbox">
          <label class="c-check no-text">
            <input
              type="checkbox"
              v-model="selectAll"
              @click="select"
              class="c-checkbox"
            />
            <div class="checkmark"></div>
          </label>
        </th>
        <th
          :width="column.width ? column.width : ''"
          :align="column.align ? column.align : ''"
          v-for="(column, index) in columns"
          :key="index"
        >
          {{ column.title }}
          <slot v-if="column.slots" :name="column.slots['title']"> </slot>
        </th>
      </tr>
      <tr
        class="loading"
        colspan="9"
        style="height: 40px; background-color: white"
        v-if="loading"
      >
        <td
          colspan="11"
          style="color: rgba(110, 118, 135, 1); font-size: 15px; text-align: center"
        >
          Загрузка ...
        </td>
      </tr>
      <!-- eslint-disable -->
      <tbody class="datas">
        <tr v-if="!loading" v-for="(row, index) in paginated" :key="row.id">
          <td v-if="cheked" :class="{ checkbox: cheked }">
            <label class="c-check no-text">
              <input
                type="checkbox"
                :value="row.id"
                v-model="selected"
                class="c-checkbox"
              />
              <div class="checkmark"></div>
            </label>
          </td>
          <td v-for="(data, indexes) in columns" :key="indexes">
            <slot
              v-if="data.scopedSlots"
              :text="dataSrc[index][data['dataIndex']]"
              :record="row"
              :name="data.scopedSlots['customRender']"
            >
            </slot>
            <span v-else> {{ dataSrc[index][data["dataIndex"]] }} </span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="table-action" style="margin-top: 18px">
      <div class="actions-head-left">
        <c-dropselect
          :options="['Опция 1', 'Опция 2']"
          placeholder="Действия с кампаниями"
          select="action"
          :placeholder="actionPlaceholder"
        >
        </c-dropselect>
        <span class="apply-btn" @click="apply()"> Применить </span>
      </div>
      <div
        class="actions-head-right"
        style="display: flex; flex-direction: row; align-items: center"
      >
        <span style="font-size: 14px; color: #6e7687"> Показывать </span>
        <c-dropselect
          style="width: 75px; margin-left: 5px; margin-right: 5px"
          :options="[5, 10, 25, 50]"
          :default="defaultRows"
          @select="setRows"
        >
        </c-dropselect>
        <span style="font-size: 14px; color: #6e7687"> строк </span>
      </div>
    </div>

    <div class="c-pagination">
      <div class="prev" @click="prev()" :class="{ disabled: pageNumber === 1 }">
        <svg
          v-svg
          symbol="arrow_left"
          size="0 0 20 20"
          role="presentation"
          class="c-icons"
        ></svg>
      </div>
      <div
        class="c-page"
        v-for="page in pages"
        :key="page"
        @click="pageClick(page)"
        :class="{ page_selected: page === pageNumber }"
      >
        {{ page }}
      </div>

      <div
        class="next"
        @click="next()"
        :class="{ disabled: pageNumber >= dataSrc.length / dataPerPage }"
      >
        <svg
          v-svg
          symbol="arrow_right"
          size="0 0 20 20"
          role="presentation"
          class="c-icons"
        ></svg>
      </div>
    </div>
  </div>
</template>

<script>
import CDropselect from "../Form/с-dropselect.vue";
import CInput from "../Form/c-input.vue";
import CDatepicker from "../Datepicker/datepicker.vue";
import Sortable from "sortablejs";
import CSearch from "../Form/c-search.vue";

function loadDND(id) {
  return JSON.parse(localStorage.getItem("DND" + id) || "[]");
}
function saveDND(data, id) {
  localStorage.setItem("DND_" + id, JSON.stringify(data));
}

export default {
  components: { CDropselect, CSearch, CDatepicker, CInput },
  data() {
    return {
      selected: [],
      selectAll: false,
      dragNdrop: [],
      pageNumber: 1,
      defaultRows: 5,
      selectedDate: [new Date(), new Date()],
      sortOptions: {
        sort_a_z: "Сортировка A-Z",
        sort_z_a: "Сортировка Z-A",
      },
      sortMode: null,
    };
  },
  props: {
    actionPlaceholder: {
      type: String,
      default: "Действия с кампаниями",
    },
    columns: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    dataSrc: {
      type: Array,
    },
    cheked: {
      type: Boolean,
      default: false,
    },
    dataPerPage: {
      type: Number,
    },
    datepicker: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "table",
    },
    attrName: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.loadDragNDrop();
    this.initSortable();
  },
  methods: {
    initSortable() {
      let table = document.querySelector("tbody");
      const _self = this;
      _self.dragNdrop = JSON.parse(JSON.stringify(_self.dataSrc));
      Sortable.create(table, {
        handle: ".handle",
        ghostClass: "sortable-ghost", // Class name for the drop placeholder
        chosenClass: "sortable-chosen", // Class name for the chosen item
        dragClass: "sortable-drag",
        forceFallback: false,
        fallbackClass: "sortable-fallback", // Class name for the cloned DOM Element when using forceFallback
        fallbackOnBody: false, // Appends the cloned DOM Element into the Document's Body

        onEnd({ newIndex, oldIndex }) {
          // console.debug("DND:", oldIndex, newIndex);
          // _self.dragNdrop = JSON.parse(JSON.stringify(_self.dataSrc));
          let newArr = _self.dataSrc.slice();
          newArr.splice(newIndex, 0, ...newArr.splice(oldIndex, 1));
          _self.saveDragNDrop(newArr);
        },
      });
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.dataSrc) {
          this.selected.push(this.dataSrc[i].id);
        }
      }
    },
    pageClick(page) {
      this.pageNumber = page;
    },

    next() {
      this.pageNumber = this.pageNumber + 1;
    },
    prev() {
      this.pageNumber = this.pageNumber - 1;
    },
    setRows(n) {
      this.defaultRows = n;
    },
    selectAction() {},
    saveDragNDrop(arr) {
      let idList = arr.map((obj) => {
        return obj.id;
      });
      console.debug("SavingDND:", this.id, idList);
      saveDND(idList, this.id);
      this.$emit("update:dataSrc", arr);
    },
    loadDragNDrop() {
      let idList = loadDND(this.id);
      console.debug("LoadingDND from IDS:", this.id, idList);
      if (!idList || !this.dataSrc) {
        return;
      }
      //
      let newArr = [];
      for (const id of idList.slice(0, 2)) {
        // Sort by idList
        for (const elem of this.dataSrc) {
          if (elem.id == id) {
            newArr.push(elem);
            continue;
          }
        }
      }
      for (const elem of this.dataSrc) {
        // Add new unsorted items at end
        if (newArr.indexOf(elem) === -1) {
          newArr.push(elem);
        }
      }
      let idList2 = newArr.map((obj) => {
        return obj.id;
      });
      console.debug("Sorted DND:", idList2, newArr);
      // this.dragNdrop = newArr;
      this.$emit("update:dataSrc", newArr);
      // let sorter = getCompaniesSorter(idList);
      // this.dataSrc.sort(sorter);
      // console.log(this.dataSrc);
      // let newArr = this.dataSrc.slice();
      // newArr.sort(sorter);
      // console.log(newArr);
      // this.dataSrc = newArr;
    },
  },
  computed: {
    pages() {
      return Math.ceil(this.dataSrc.length / this.dataPerPage);
    },
    paginated() {
      let from = (this.pageNumber - 1) * this.dataPerPage;
      let to = from + this.dataPerPage;
      let rawData = this.dataSrc.slice(from, to);

      // Sorting
      let this_ = this;

      rawData.sort(function (a, b) {
        if (!this_.attrName) {
          return 0;
        }
        let n1 = a[this_.attrName];
        let n2 = b[this_.attrName];
        let smode = this_.sortMode;
        let soptions = this_.sortOptions;

        if (smode == soptions.sort_a_z) {
          if (n1 > n2) {
            return 1;
          }
          if (n1 < n2) {
            return -1;
          }
        } else if (smode == soptions.sort_z_a) {
          if (n1 > n2) {
            return -1;
          }
          if (n1 < n2) {
            return 1;
          }
        }
        return 0;
      });
      // -/Sorting

      return rawData;
    },
  },
  watch: {
    defaultRows(newVal) {
      this.$emit("update:dataPerPage", newVal);
    },
    // dataSrc(newVal) {
    //   this.loadDragNDrop();
    // },
  },
};
</script>

<style lang="scss">
.checkbox {
  min-width: 50px;

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
