<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <svg
          @click="close()"
          v-svg
          symbol="icon_x"
          size="0 0 30 30"
          role="presentation"
          class="close-icon"
        ></svg>
        <div class="modal-container">
          <header class="modal-header">
            <slot name="header">
              <h1>{{ title }}</h1>
            </slot>
          </header>
          <section class="modal-body">
            <slot> </slot>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Unnamed title",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.modal-container {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
}

label {
  color: rgba(45, 67, 80, 1);
  font-size: 17px;
  font-weight: 500;
}

.modal {
  background: rgba(242, 243, 248, 1);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 1043px;
  height: 776px;
  border-radius: 4px;
  position: relative;
  z-index: 99;
}

.form-input {
  width: 300px;
  height: 40px;
}

.form-create {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-col {
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  display: flex;
  margin-top: 70px;
}

.modal-header {
  color: #4aae9b;
  position: relative;
  margin-bottom: 50px;
}

.modal-footer {
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.close-icon {
  border: none;
  cursor: pointer;
  font-weight: bold;
  fill: rgba(167, 177, 192, 1);
  background: transparent;
  // right: -100px;
  position: absolute;
  top: 30px;
  right: 30px;
}
</style>
