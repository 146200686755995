<template>
  <select class="select">
    <option class="option">RU</option>
    <option>EN</option>
    <option>ES</option>
  </select>
</template>

<script>
export default {};
</script>

<style>
select {
  outline: none;
  font-family: "Lato";
}
.select {
  border: 0px;
  background: transparent;
  font-size: 16px;
  color: rgba(93, 97, 174, 1);
}

.select:focus-visible {
  border: 1px solid transparent;
}

option {
  font-weight: bolder;
  background: white;
  font-family: "Lato";
}
</style>
