<template>
  <nav class="sider" :selected="active" :class="{ collapse: isCollapsed }">
    <div class="logo">
      <img class="logo-img" src="" />
    </div>
    <ul class="menu">
      <li>
        <a
          class="menu-item"
          @click="select('1', '/app/statistic')"
          :class="active === '1' ? 'active' : ''"
        >
          <span class="menu-icon">
            <svg
              v-svg
              symbol="icon_bar_chart"
              width="16"
              height="16"
              size="0 0 16 16"
              role="presentation"
              class="icon--inline"
            ></svg>
          </span>
          <span class="menu-title"> Статистика </span>
        </a>
      </li>
      <li>
        <a
          class="menu-item"
          @click="select('2', '/app/campaigns')"
          :class="active === '2' ? 'active' : ''"
        >
          <span class="menu-icon">
            <svg
              v-svg
              symbol="icon_home"
              width="16"
              height="16"
              size="0 0 16 16"
              role="presentation"
              class="icon--inline"
            ></svg>
          </span>
          <span class="menu-title"> Кампании </span>
        </a>
      </li>
      <li>
        <a class="menu-item" :class="active === '3' ? 'active' : ''">
          <span class="menu-icon">
            <svg
              v-svg
              symbol="icon_link"
              width="16"
              height="16"
              size="0 0 16 16"
              role="presentation"
              class="icon--inline"
            ></svg>
          </span>
          <span class="menu-title"> Лендинги </span>
          <svg
            v-svg
            symbol="arrow_down2"
            size="0 0 16 17"
            role="presentation"
            class=""
          ></svg>
        </a>
        <ul class="submenu">
          <li>
            <a
              class="submenu-item"
              @click="select('3', '/app/landings/white-pages')"
              :class="active === '3' ? 'active' : ''"
            >
              White Pages
            </a>
          </li>
          <li>
            <a
              class="submenu-item"
              @click="select('3', '/app/landings/black-pages')"
              :class="active === '3' ? 'active' : ''"
            >
              Black Pages
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a
          class="menu-item"
          @click="select('4', '/app/domains')"
          :class="active === '4' ? 'active' : ''"
        >
          <span class="menu-icon">
            <svg
              v-svg
              symbol="icon_domain"
              width="16"
              height="16"
              size="0 0 16 16"
              role="presentation"
              class="icon--inline"
            ></svg>
          </span>
          <span class="menu-title"> Домены </span>
        </a>
      </li>
      <li>
        <a
          class="menu-item"
          @click="select('5', '/app/filters')"
          :class="active === '5' ? 'active' : ''"
        >
          <span class="menu-icon">
            <svg
              v-svg
              symbol="icon_filter"
              width="16"
              height="16"
              size="0 0 16 16"
              role="presentation"
              class="icon--inline"
            ></svg>
          </span>
          <span class="menu-title"> Фильтры </span>
        </a>
      </li>
      <hr class="menu-divider" />
      <a
        class="menu-item"
        @click="select('6', '/app/faq')"
        :class="active === '6' ? 'active' : ''"
      >
        <span class="menu-icon">
          <svg
            v-svg
            symbol="icon_help_circle_stroke"
            width="16"
            height="16"
            size="0 0 16 16"
            role="presentation"
            class="icon--inline"
          ></svg>
        </span>
        <span class="menu-title"> FAQ </span>
      </a>
      <a
        class="menu-item"
        @click="select('7', '/app/settings')"
        :class="active === '7' ? 'active' : ''"
      >
        <span class="menu-icon">
          <svg
            v-svg
            symbol="settings"
            width="16"
            height="16"
            size="0 0 16 16"
            role="presentation"
            class="icon--inline"
          ></svg>
        </span>
        <span class="menu-title"> Настройки </span>
      </a>
      <a
        class="menu-item"
        @click="select('8', '/app/support')"
        :class="active === '8' ? 'active' : ''"
      >
        <span class="menu-icon">
          <svg
            v-svg
            symbol="headphones"
            width="16"
            height="16"
            size="0 0 16 16"
            role="presentation"
            class="icon--inline"
          ></svg>
        </span>
        <span class="menu-title"> Поддержка </span>
      </a>
    </ul>

    <span @click="isCollapsed = !isCollapsed" class="togle-item">
      <span class="menu-icon">
        <div class="circle">
          <svg
            v-svg
            symbol="arrow_up"
            size="-4 -3 24 24"
            role="presentation"
            fill="black"
            class="icon--inline"
          ></svg>
        </div>
      </span>
      <span class="toogle-title"> Свернуть панель </span>
    </span>
  </nav>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
    },
  },
  data() {
    return {
      isActive: false,
      isCollapsed: false,
    };
  },
  methods: {
    select(item, href) {
      this.$router.push(href).catch(() => {});
      this.$emit("changeActive", item);
    },
  },
};
</script>
