export default {
  state: {
    status: "",
    campaigns: [],
    loading: false,

  },
  mutations: {

    request_get_campaigns(state) {
      state.status = 'loading'
      state.loading = true;
    },
    request_get_campaigns_success(state, campaigns) {
      state.status = 'success'
      state.campaigns = campaigns
      state.loading = false

    },
    request_get_campaigns_error(state) {
      state.status = 'error'
      state.campaigns = []
      state.loading = false
    }

  },
  actions: {

    getCampaigns({ commit }) {
      commit('request_get_campaigns')
      function getRandom(n, m) {
        let white = Math.floor(Math.random() * (m - n));
        let black = Math.floor(Math.random() * (m - n));
        let total = white + black;
        let percent = (black / total * 100).toFixed();
        return { "white": white, "black": black, "total": total, "percent": percent };

      };
      let campaigns = [{
        "id": 1,
        "status": "1",
        "name": "CBD_DE_ML #1",
        "sub": "insta",
        "traffic_page": "https://founds.ru",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://kinopoisk.ru",
        "created": "",
        "updated": "",

        "stat": getRandom(0, 1000),


      },
      {
        "id": 2,
        "status": "2",
        "name": "CBD_DE_ML #2",
        "sub": "facebook",
        "traffic_page": "https://tovar-shop.ru",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://ozon.ru",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 3,
        "status": "2",
        "name": "CBD_DE_ML #3",
        "sub": "insta",
        "traffic_page": "https://100details.com",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://goods.ru",
        "created": "",
        "updated": "",

        "stat": getRandom(0, 1000),


      },
      {
        "id": 4,
        "status": "1",
        "name": "CBD_DE_ML #4",
        "sub": "tik tok",
        "traffic_page": "https://powerbank.xyz",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://ozon.ru/shop/categories/rwrwrwr/5rwfw/435643",
        "created": "",
        "updated": "",

        "stat": getRandom(0, 1000),



      },
      {
        "id": 5,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 6,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 7,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 8,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 9,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 10,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 11,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 12,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 13,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 14,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 15,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 16,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 17,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 18,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 19,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 20,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 21,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 22,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 23,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 24,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },
      {
        "id": 25,
        "status": "2",
        "name": "CBD_DE_ML #5",
        "sub": "youtube",
        "traffic_page": "https://рисуй-в-темноте.рф",
        "black_page": "https://founds-black.xyz",
        "white_page": "https://detmir.ru/risui-v-temnote",
        "created": "",
        "updated": "",
        "stat": getRandom(0, 1000),


      },


      ]
      commit('request_get_campaigns_success', campaigns)


    },
    deleteCampaign({ commit, campaign }) {

    },

    filteredCampaigns({ commit }, search) {
      console.log("----")
      let filtered = this.state.campaigns.campaigns.filter(el => el.sub == search);
      commit('request_get_campaigns_success', filtered)






    }

  },
  getters: {

    Campaigns: state => state.campaigns,
    Status: state => state.status,
    Loading: state => state.loading

  }
}



