<template>
  <div class="container-stat">
    <div class="total-container">
      <div class="statistic">
        <div class="clicks">
          <div class="c-title">
            <h2>Клики</h2>
          </div>
          <div class="clicks-data">
            <div class="total">
              <p class="count">2 331</p>
              <p class="label">Всего</p>
            </div>
            <div class="black">
              <p class="count">1 212</p>
              <p class="label">Black</p>
            </div>
            <div class="white">
              <p class="count">1 275</p>
              <p class="label">White</p>
            </div>
          </div>
        </div>
        <div class="whites">
          <div class="c-title">
            <h2>Сгенерировано вайтов</h2>
            <div class="whites-data">
              <p>25/50</p>
            </div>
            <div class="btn-upd">
              <p>Повысить лимит</p>
            </div>
          </div>
        </div>

        <div class="domains">
          <div class="c-title">
            <h2>Домены</h2>
            <div class="domains-data">
              <p>10/30</p>
            </div>
            <div class="btn-upd">
              <p>Повысить лимит</p>
            </div>
          </div>
        </div>
      </div>

      <div class="chart">
        <div class="stat">
          <TrendChart
            :datasets="[
              {
                data: [100, 324, 230, 400, 545, 957],
                smooth: false,
                fill: false,
                showPoints: true,
                className: 'total',
              },
              {
                data: [327, 235, 432, 563, 500, 876],
                smooth: false,
                fill: false,
                showPoints: true,
                className: 'black',
              },
              {
                data: [256, 321, 432, 307, 563, 901],
                smooth: false,
                fill: false,
                showPoints: true,
                className: 'white',
              },
            ]"
            :interactive="true"
            :grid="{
              horizontalLines: true,
            }"
            :labels="{
              xLabels: ['15.10', '16.10', '17.10', '18.10', '19.10', '20.10', '21.10'],
              yLabels: 5,
              yLabelsTextFormatter: (val) => Math.round(val) / 5,
            }"
            showPoints
            :min="0"
          >
          </TrendChart>
        </div>
      </div>
    </div>
    <div class="filter">
      <div class="filter-date">
        <div class="c-title">
          <h2>Кампания и период</h2>
        </div>
        <div class="filter-group">
          <c-dropselect
            style="width: 100%; margin: 5px"
            :options="['Item 1', 'Item 2', 'Item 3']"
            placeholder="Все кампании"
          >
          </c-dropselect>
          <c-datepicker
            range
            lang="ru"
            style="margin: 5px"
            :date-format="{
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }"
          ></c-datepicker>
          <button class="btn-main" style="margin-top: 20px">Показать статистику</button>
        </div>
      </div>
      <div class="filter-method">
        <div class="c-title">
          <h2>Метод фильтрации</h2>
          <div class="methods-data">
            <div class="row">
              <p>Всего кликов</p>
              <p>25</p>
            </div>
            <div class="row">
              <p>Отфильтровано</p>
              <p>25</p>
            </div>
            <div class="row">
              <p>Процент фильтрации</p>
              <p>100%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CDatepicker from "../Datepicker/datepicker.vue";
import CDropselect from "../Form/с-dropselect.vue";
export default {
  components: { CDropselect, CDatepicker },
  data() {
    return {
      value: "",
    };
  },
};
</script>

<style>
.methods-data {
  display: flex;
  flex-direction: column;
  padding: 30px 0px 30px;
}

.row {
  display: flex;
  flex-direction: row;
  font-size: 17px;
  color: rgba(96, 111, 137, 1);
  height: 40px;
  justify-content: space-between;
}

.filter {
  margin-left: 30px;
}

.btn-main {
  background: rgba(48, 53, 169, 1);
  color: white;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  border: 0;
  cursor: pointer;
}

.filter-group {
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px;
}
.total-container {
  display: flex;
  flex-direction: column;
  color: rgba(45, 67, 80, 1);
}

.statistic {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.clicks,
.whites,
.domains,
.filter-date,
.filter-method {
  display: flex;
  flex-direction: column;
  width: 366px;
  height: 170px;
  background: #f9f8ff;
  border: 1px solid #cfd4da;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 40px 20px;
}

.whites {
  width: 366px;
  height: 170px;
  background: #f9f8ff;
  border: 1px solid #cfd4da;
  box-sizing: border-box;
  border-radius: 4px;
}

.c-title {
  color: rgba(45, 67, 80, 1);
}

.domains {
  width: 366px;
  height: 170px;
  background: #f9f8ff;
  border: 1px solid #cfd4da;
  box-sizing: border-box;
  border-radius: 4px;
}

.stat {
  width: 1118px;
  height: 400px;
  background: #f9f8ff;
  border: 1px solid #cfd4da;
  border-radius: 4px;
  padding: 20px;
}
.chart {
  margin-top: 15px;
}

.filter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container-stat {
  display: flex;
  justify-content: space-between;
}

.filter-date {
  width: 366px;
  height: 300px;
  background: #f9f8ff;
  border: 1px solid #cfd4da;
  box-sizing: border-box;
  border-radius: 4px;
}

.filter-method {
  width: 366px;
  height: 272px;
  background: #f9f8ff;
  border: 1px solid #cfd4da;
  box-sizing: border-box;
  border-radius: 4px;
}

.clicks-data,
.whites-data,
.domains-data {
  display: flex;
  font-size: 25px;
  justify-content: space-between;
  width: 70%;
  margin-top: 10px;
}

.clicks-data .total {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 12px;
  color: rgba(45, 67, 80, 1);
}

.total {
  color: rgba(45, 67, 80, 1);
}
.black {
  color: rgba(10, 180, 191, 1);
}

.white {
  color: rgba(220, 155, 18, 1);
}

.white,
.black,
.curve {
  stroke-width: 2;
}

.white.curve .stroke,
.point {
  stroke: #dc9b12 !important;
}

.black.curve .stroke {
  stroke: #0ab4bf !important;
}

.total.curve .stroke {
  stroke: #2d4350 !important;
}

.y-labels,
.x-labels,
.label {
  fill: rgba(110, 118, 135, 1);
  font-size: 12px;
}

.white.curve .point {
  stroke: #dc9b12 !important;
  r: 8;
  stroke-width: 2px;
  fill: white;
}

.black.curve .point {
  stroke: #0ab4bf !important;
  r: 8;
  stroke-width: 2px;
  fill: white;
}

.total.curve .point {
  stroke: #2d4350 !important;
  r: 8;
  stroke-width: 2px;
  fill: white;
}

.active-line {
  stroke: rgba(110, 118, 135, 1) !important;
}

.btn-upd {
  padding: 10px 0px 10px;
  color: #5d61ae;
  font-size: 16px;
}

h2 {
  margin: 0;
}
</style>
;
