let countries = [
    {
        "name": "Абхазия",
        "fullname": "Республика Абхазия",
        "english": "Abkhazia",
        "alpha2": "AB",
        "alpha3": "ABH",
        "iso": "895",
        "location": "Азия",
        "location-precise": "Закавказье"
    },
    {
        "name": "Австралия",
        "fullname": " ",
        "english": "Australia",
        "alpha2": "AU",
        "alpha3": "AUS",
        "iso": "036",
        "location": "Океания",
        "location-precise": "Австралия и Новая Зеландия"
    },
    {
        "name": "Австрия",
        "fullname": "Австрийская Республика",
        "english": "Austria",
        "alpha2": "AT",
        "alpha3": "AUT",
        "iso": "040",
        "location": "Европа",
        "location-precise": "Западная Европа"
    },
    {
        "name": "Азербайджан",
        "fullname": "Республика Азербайджан",
        "english": "Azerbaijan",
        "alpha2": "AZ",
        "alpha3": "AZE",
        "iso": "031",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Албания",
        "fullname": "Республика Албания",
        "english": "Albania",
        "alpha2": "AL",
        "alpha3": "ALB",
        "iso": "008",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Алжир",
        "fullname": "Алжирская Народная Демократическая Республика",
        "english": "Algeria",
        "alpha2": "DZ",
        "alpha3": "DZA",
        "iso": "012",
        "location": "Африка",
        "location-precise": "Северная Африка"
    },
    {
        "name": "Американское Самоа",
        "fullname": " ",
        "english": "American Samoa",
        "alpha2": "AS",
        "alpha3": "ASM",
        "iso": "016",
        "location": "Океания",
        "location-precise": "Полинезия"
    },
    {
        "name": "Ангилья",
        "fullname": " ",
        "english": "Anguilla",
        "alpha2": "AI",
        "alpha3": "AIA",
        "iso": "660",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Ангола",
        "fullname": "Республика Ангола",
        "english": "Angola",
        "alpha2": "AO",
        "alpha3": "AGO",
        "iso": "024",
        "location": "Африка",
        "location-precise": "Центральная Африка"
    },
    {
        "name": "Андорра",
        "fullname": "Княжество Андорра",
        "english": "Andorra",
        "alpha2": "AD",
        "alpha3": "AND",
        "iso": "020",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Антарктида",
        "fullname": " ",
        "english": "Antarctica",
        "alpha2": "AQ",
        "alpha3": "ATA",
        "iso": "010",
        "location": "Антарктика",
        "location-precise": " "
    },
    {
        "name": "Антигуа и Барбуда",
        "fullname": " ",
        "english": "Antigua and Barbuda",
        "alpha2": "AG",
        "alpha3": "ATG",
        "iso": "028",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Аргентина",
        "fullname": "Аргентинская Республика",
        "english": "Argentina",
        "alpha2": "AR",
        "alpha3": "ARG",
        "iso": "032",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Армения",
        "fullname": "Республика Армения",
        "english": "Armenia",
        "alpha2": "AM",
        "alpha3": "ARM",
        "iso": "051",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Аруба",
        "fullname": " ",
        "english": "Aruba",
        "alpha2": "AW",
        "alpha3": "ABW",
        "iso": "533",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Афганистан",
        "fullname": "Переходное Исламское Государство Афганистан",
        "english": "Afghanistan",
        "alpha2": "AF",
        "alpha3": "AFG",
        "iso": "004",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Багамы",
        "fullname": "Содружество Багамы",
        "english": "Bahamas",
        "alpha2": "BS",
        "alpha3": "BHS",
        "iso": "044",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Бангладеш",
        "fullname": "Народная Республика Бангладеш",
        "english": "Bangladesh",
        "alpha2": "BD",
        "alpha3": "BGD",
        "iso": "050",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Барбадос",
        "fullname": " ",
        "english": "Barbados",
        "alpha2": "BB",
        "alpha3": "BRB",
        "iso": "052",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Бахрейн",
        "fullname": "Королевство Бахрейн",
        "english": "Bahrain",
        "alpha2": "BH",
        "alpha3": "BHR",
        "iso": "048",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Беларусь",
        "fullname": "Республика Беларусь",
        "english": "Belarus",
        "alpha2": "BY",
        "alpha3": "BLR",
        "iso": "112",
        "location": "Европа",
        "location-precise": "Восточная Европа"
    },
    {
        "name": "Белиз",
        "fullname": " ",
        "english": "Belize",
        "alpha2": "BZ",
        "alpha3": "BLZ",
        "iso": "084",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Бельгия",
        "fullname": "Королевство Бельгии",
        "english": "Belgium",
        "alpha2": "BE",
        "alpha3": "BEL",
        "iso": "056",
        "location": "Европа",
        "location-precise": "Западная Европа"
    },
    {
        "name": "Бенин",
        "fullname": "Республика Бенин",
        "english": "Benin",
        "alpha2": "BJ",
        "alpha3": "BEN",
        "iso": "204",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Бермуды",
        "fullname": " ",
        "english": "Bermuda",
        "alpha2": "BM",
        "alpha3": "BMU",
        "iso": "060",
        "location": "Америка",
        "location-precise": "Северная Америка"
    },
    {
        "name": "Болгария",
        "fullname": "Республика Болгария",
        "english": "Bulgaria",
        "alpha2": "BG",
        "alpha3": "BGR",
        "iso": "100",
        "location": "Европа",
        "location-precise": "Восточная Европа"
    },
    {
        "name": "Боливия, Многонациональное Государство",
        "fullname": "Многонациональное Государство Боливия",
        "english": "Bolivia, plurinational state of",
        "alpha2": "BO",
        "alpha3": "BOL",
        "iso": "068",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Бонайре, Саба и Синт-Эстатиус",
        "fullname": " ",
        "english": "Bonaire, Sint Eustatius and Saba",
        "alpha2": "BQ",
        "alpha3": "BES",
        "iso": "535",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Босния и Герцеговина",
        "fullname": " ",
        "english": "Bosnia and Herzegovina",
        "alpha2": "BA",
        "alpha3": "BIH",
        "iso": "070",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Ботсвана",
        "fullname": "Республика Ботсвана",
        "english": "Botswana",
        "alpha2": "BW",
        "alpha3": "BWA",
        "iso": "072",
        "location": "Африка",
        "location-precise": "Южная часть Африки"
    },
    {
        "name": "Бразилия",
        "fullname": "Федеративная Республика Бразилия",
        "english": "Brazil",
        "alpha2": "BR",
        "alpha3": "BRA",
        "iso": "076",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Британская территория в Индийском океане",
        "fullname": " ",
        "english": "British Indian Ocean Territory",
        "alpha2": "IO",
        "alpha3": "IOT",
        "iso": "086",
        "location": "Океания",
        "location-precise": "Индийский океан"
    },
    {
        "name": "Бруней-Даруссалам",
        "fullname": " ",
        "english": "Brunei Darussalam",
        "alpha2": "BN",
        "alpha3": "BRN",
        "iso": "096",
        "location": "Азия",
        "location-precise": "Юго-Восточная Азия"
    },
    {
        "name": "Буркина-Фасо",
        "fullname": " ",
        "english": "Burkina Faso",
        "alpha2": "BF",
        "alpha3": "BFA",
        "iso": "854",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Бурунди",
        "fullname": "Республика Бурунди",
        "english": "Burundi",
        "alpha2": "BI",
        "alpha3": "BDI",
        "iso": "108",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Бутан",
        "fullname": "Королевство Бутан",
        "english": "Bhutan",
        "alpha2": "BT",
        "alpha3": "BTN",
        "iso": "064",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Вануату",
        "fullname": "Республика Вануату",
        "english": "Vanuatu",
        "alpha2": "VU",
        "alpha3": "VUT",
        "iso": "548",
        "location": "Океания",
        "location-precise": "Меланезия"
    },
    {
        "name": "Венгрия",
        "fullname": "Венгерская Республика",
        "english": "Hungary",
        "alpha2": "HU",
        "alpha3": "HUN",
        "iso": "348",
        "location": "Европа",
        "location-precise": "Восточная Европа"
    },
    {
        "name": "Венесуэла Боливарианская Республика",
        "fullname": "Боливарийская Республика Венесуэла",
        "english": "Venezuela",
        "alpha2": "VE",
        "alpha3": "VEN",
        "iso": "862",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Виргинские острова, Британские",
        "fullname": "Британские Виргинские острова",
        "english": "Virgin Islands, British",
        "alpha2": "VG",
        "alpha3": "VGB",
        "iso": "092",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Виргинские острова, США",
        "fullname": "Виргинские острова Соединенных Штатов",
        "english": "Virgin Islands, U.S.",
        "alpha2": "VI",
        "alpha3": "VIR",
        "iso": "850",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Вьетнам",
        "fullname": "Социалистическая Республика Вьетнам",
        "english": "Vietnam",
        "alpha2": "VN",
        "alpha3": "VNM",
        "iso": "704",
        "location": "Азия",
        "location-precise": "Юго-Восточная Азия"
    },
    {
        "name": "Габон",
        "fullname": "Габонская Республика",
        "english": "Gabon",
        "alpha2": "GA",
        "alpha3": "GAB",
        "iso": "266",
        "location": "Африка",
        "location-precise": "Центральная Африка"
    },
    {
        "name": "Гаити",
        "fullname": "Республика Гаити",
        "english": "Haiti",
        "alpha2": "HT",
        "alpha3": "HTI",
        "iso": "332",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Гайана",
        "fullname": "Республика Гайана",
        "english": "Guyana",
        "alpha2": "GY",
        "alpha3": "GUY",
        "iso": "328",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Гамбия",
        "fullname": "Республика Гамбия",
        "english": "Gambia",
        "alpha2": "GM",
        "alpha3": "GMB",
        "iso": "270",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Гана",
        "fullname": "Республика Гана",
        "english": "Ghana",
        "alpha2": "GH",
        "alpha3": "GHA",
        "iso": "288",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Гваделупа",
        "fullname": " ",
        "english": "Guadeloupe",
        "alpha2": "GP",
        "alpha3": "GLP",
        "iso": "312",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Гватемала",
        "fullname": "Республика Гватемала",
        "english": "Guatemala",
        "alpha2": "GT",
        "alpha3": "GTM",
        "iso": "320",
        "location": "Америка",
        "location-precise": "Центральная Америка"
    },
    {
        "name": "Гвинея",
        "fullname": "Гвинейская Республика",
        "english": "Guinea",
        "alpha2": "GN",
        "alpha3": "GIN",
        "iso": "324",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Гвинея-Бисау",
        "fullname": "Республика Гвинея-Бисау",
        "english": "Guinea-Bissau",
        "alpha2": "GW",
        "alpha3": "GNB",
        "iso": "624",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Германия",
        "fullname": "Федеративная Республика Германия",
        "english": "Germany",
        "alpha2": "DE",
        "alpha3": "DEU",
        "iso": "276",
        "location": "Европа",
        "location-precise": "Западная Европа"
    },
    {
        "name": "Гернси",
        "fullname": " ",
        "english": "Guernsey",
        "alpha2": "GG",
        "alpha3": "GGY",
        "iso": "831",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Гибралтар",
        "fullname": " ",
        "english": "Gibraltar",
        "alpha2": "GI",
        "alpha3": "GIB",
        "iso": "292",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Гондурас",
        "fullname": "Республика Гондурас",
        "english": "Honduras",
        "alpha2": "HN",
        "alpha3": "HND",
        "iso": "340",
        "location": "Америка",
        "location-precise": "Центральная Америка"
    },
    {
        "name": "Гонконг",
        "fullname": "Специальный административный регион Китая Гонконг",
        "english": "Hong Kong",
        "alpha2": "HK",
        "alpha3": "HKG",
        "iso": "344",
        "location": "Азия",
        "location-precise": "Восточная Азия"
    },
    {
        "name": "Гренада",
        "fullname": " ",
        "english": "Grenada",
        "alpha2": "GD",
        "alpha3": "GRD",
        "iso": "308",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Гренландия",
        "fullname": " ",
        "english": "Greenland",
        "alpha2": "GL",
        "alpha3": "GRL",
        "iso": "304",
        "location": "Америка",
        "location-precise": "Северная Америка"
    },
    {
        "name": "Греция",
        "fullname": "Греческая Республика",
        "english": "Greece",
        "alpha2": "GR",
        "alpha3": "GRC",
        "iso": "300",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Грузия",
        "fullname": " ",
        "english": "Georgia",
        "alpha2": "GE",
        "alpha3": "GEO",
        "iso": "268",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Гуам",
        "fullname": " ",
        "english": "Guam",
        "alpha2": "GU",
        "alpha3": "GUM",
        "iso": "316",
        "location": "Океания",
        "location-precise": "Микронезия"
    },
    {
        "name": "Дания",
        "fullname": "Королевство Дания",
        "english": "Denmark",
        "alpha2": "DK",
        "alpha3": "DNK",
        "iso": "208",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Джерси",
        "fullname": " ",
        "english": "Jersey",
        "alpha2": "JE",
        "alpha3": "JEY",
        "iso": "832",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Джибути",
        "fullname": "Республика Джибути",
        "english": "Djibouti",
        "alpha2": "DJ",
        "alpha3": "DJI",
        "iso": "262",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Доминика",
        "fullname": "Содружество Доминики",
        "english": "Dominica",
        "alpha2": "DM",
        "alpha3": "DMA",
        "iso": "212",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Доминиканская Республика",
        "fullname": " ",
        "english": "Dominican Republic",
        "alpha2": "DO",
        "alpha3": "DOM",
        "iso": "214",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Египет",
        "fullname": "Арабская Республика Египет",
        "english": "Egypt",
        "alpha2": "EG",
        "alpha3": "EGY",
        "iso": "818",
        "location": "Африка",
        "location-precise": "Северная Африка"
    },
    {
        "name": "Замбия",
        "fullname": "Республика Замбия",
        "english": "Zambia",
        "alpha2": "ZM",
        "alpha3": "ZMB",
        "iso": "894",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Западная Сахара",
        "fullname": " ",
        "english": "Western Sahara",
        "alpha2": "EH",
        "alpha3": "ESH",
        "iso": "732",
        "location": "Африка",
        "location-precise": "Северная Африка"
    },
    {
        "name": "Зимбабве",
        "fullname": "Республика Зимбабве",
        "english": "Zimbabwe",
        "alpha2": "ZW",
        "alpha3": "ZWE",
        "iso": "716",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Израиль",
        "fullname": "Государство Израиль",
        "english": "Israel",
        "alpha2": "IL",
        "alpha3": "ISR",
        "iso": "376",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Индия",
        "fullname": "Республика Индия",
        "english": "India",
        "alpha2": "IN",
        "alpha3": "IND",
        "iso": "356",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Индонезия",
        "fullname": "Республика Индонезия",
        "english": "Indonesia",
        "alpha2": "ID",
        "alpha3": "IDN",
        "iso": "360",
        "location": "Азия",
        "location-precise": "Юго-Восточная Азия"
    },
    {
        "name": "Иордания",
        "fullname": "Иорданское Хашимитское Королевство",
        "english": "Jordan",
        "alpha2": "JO",
        "alpha3": "JOR",
        "iso": "400",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Ирак",
        "fullname": "Республика Ирак",
        "english": "Iraq",
        "alpha2": "IQ",
        "alpha3": "IRQ",
        "iso": "368",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Иран, Исламская Республика",
        "fullname": "Исламская Республика Иран",
        "english": "Iran, Islamic Republic of",
        "alpha2": "IR",
        "alpha3": "IRN",
        "iso": "364",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Ирландия",
        "fullname": " ",
        "english": "Ireland",
        "alpha2": "IE",
        "alpha3": "IRL",
        "iso": "372",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Исландия",
        "fullname": "Республика Исландия",
        "english": "Iceland",
        "alpha2": "IS",
        "alpha3": "ISL",
        "iso": "352",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Испания",
        "fullname": "Королевство Испания",
        "english": "Spain",
        "alpha2": "ES",
        "alpha3": "ESP",
        "iso": "724",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Италия",
        "fullname": "Итальянская Республика",
        "english": "Italy",
        "alpha2": "IT",
        "alpha3": "ITA",
        "iso": "380",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Йемен",
        "fullname": "Йеменская Республика",
        "english": "Yemen",
        "alpha2": "YE",
        "alpha3": "YEM",
        "iso": "887",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Кабо-Верде",
        "fullname": "Республика Кабо-Верде",
        "english": "Cape Verde",
        "alpha2": "CV",
        "alpha3": "CPV",
        "iso": "132",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Казахстан",
        "fullname": "Республика Казахстан",
        "english": "Kazakhstan",
        "alpha2": "KZ",
        "alpha3": "KAZ",
        "iso": "398",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Камбоджа",
        "fullname": "Королевство Камбоджа",
        "english": "Cambodia",
        "alpha2": "KH",
        "alpha3": "KHM",
        "iso": "116",
        "location": "Азия",
        "location-precise": "Юго-Восточная Азия"
    },
    {
        "name": "Камерун",
        "fullname": "Республика Камерун",
        "english": "Cameroon",
        "alpha2": "CM",
        "alpha3": "CMR",
        "iso": "120",
        "location": "Африка",
        "location-precise": "Центральная Африка"
    },
    {
        "name": "Канада",
        "fullname": " ",
        "english": "Canada",
        "alpha2": "CA",
        "alpha3": "CAN",
        "iso": "124",
        "location": "Америка",
        "location-precise": "Северная Америка"
    },
    {
        "name": "Катар",
        "fullname": "Государство Катар",
        "english": "Qatar",
        "alpha2": "QA",
        "alpha3": "QAT",
        "iso": "634",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Кения",
        "fullname": "Республика Кения",
        "english": "Kenya",
        "alpha2": "KE",
        "alpha3": "KEN",
        "iso": "404",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Кипр",
        "fullname": "Республика Кипр",
        "english": "Cyprus",
        "alpha2": "CY",
        "alpha3": "CYP",
        "iso": "196",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Киргизия",
        "fullname": "Киргизская Республика",
        "english": "Kyrgyzstan",
        "alpha2": "KG",
        "alpha3": "KGZ",
        "iso": "417",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Кирибати",
        "fullname": "Республика Кирибати",
        "english": "Kiribati",
        "alpha2": "KI",
        "alpha3": "KIR",
        "iso": "296",
        "location": "Океания",
        "location-precise": "Микронезия"
    },
    {
        "name": "Китай",
        "fullname": "Китайская Народная Республика",
        "english": "China",
        "alpha2": "CN",
        "alpha3": "CHN",
        "iso": "156",
        "location": "Азия",
        "location-precise": "Восточная Азия"
    },
    {
        "name": "Кокосовые (Килинг) острова",
        "fullname": " ",
        "english": "Cocos (Keeling) Islands",
        "alpha2": "CC",
        "alpha3": "CCK",
        "iso": "166",
        "location": "Океания",
        "location-precise": "Индийский океан"
    },
    {
        "name": "Колумбия",
        "fullname": "Республика Колумбия",
        "english": "Colombia",
        "alpha2": "CO",
        "alpha3": "COL",
        "iso": "170",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Коморы",
        "fullname": "Союз Коморы",
        "english": "Comoros",
        "alpha2": "KM",
        "alpha3": "COM",
        "iso": "174",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Конго",
        "fullname": "Республика Конго",
        "english": "Congo",
        "alpha2": "CG",
        "alpha3": "COG",
        "iso": "178",
        "location": "Африка",
        "location-precise": "Центральная Африка"
    },
    {
        "name": "Конго, Демократическая Республика",
        "fullname": "Демократическая Республика Конго",
        "english": "Congo, Democratic Republic of the",
        "alpha2": "CD",
        "alpha3": "COD",
        "iso": "180",
        "location": "Африка",
        "location-precise": "Центральная Африка"
    },
    {
        "name": "Корея, Народно-Демократическая Республика",
        "fullname": "Корейская Народно-Демократическая Республика",
        "english": "Korea, Democratic People's republic of",
        "alpha2": "KP",
        "alpha3": "PRK",
        "iso": "408",
        "location": "Азия",
        "location-precise": "Восточная Азия"
    },
    {
        "name": "Корея, Республика",
        "fullname": "Республика Корея",
        "english": "Korea, Republic of",
        "alpha2": "KR",
        "alpha3": "KOR",
        "iso": "410",
        "location": "Азия",
        "location-precise": "Восточная Азия"
    },
    {
        "name": "Коста-Рика",
        "fullname": "Республика Коста-Рика",
        "english": "Costa Rica",
        "alpha2": "CR",
        "alpha3": "CRI",
        "iso": "188",
        "location": "Америка",
        "location-precise": "Центральная Америка"
    },
    {
        "name": "Кот д'Ивуар",
        "fullname": "Республика Кот д'Ивуар",
        "english": "Cote d'Ivoire",
        "alpha2": "CI",
        "alpha3": "CIV",
        "iso": "384",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Куба",
        "fullname": "Республика Куба",
        "english": "Cuba",
        "alpha2": "CU",
        "alpha3": "CUB",
        "iso": "192",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Кувейт",
        "fullname": "Государство Кувейт",
        "english": "Kuwait",
        "alpha2": "KW",
        "alpha3": "KWT",
        "iso": "414",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Кюрасао",
        "fullname": " ",
        "english": "Curaçao",
        "alpha2": "CW",
        "alpha3": "CUW",
        "iso": "531",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Лаос",
        "fullname": "Лаосская Народно-Демократическая Республика",
        "english": "Lao People's Democratic Republic",
        "alpha2": "LA",
        "alpha3": "LAO",
        "iso": "418",
        "location": "Азия",
        "location-precise": "Юго-Восточная Азия"
    },
    {
        "name": "Латвия",
        "fullname": "Латвийская Республика",
        "english": "Latvia",
        "alpha2": "LV",
        "alpha3": "LVA",
        "iso": "428",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Лесото",
        "fullname": "Королевство Лесото",
        "english": "Lesotho",
        "alpha2": "LS",
        "alpha3": "LSO",
        "iso": "426",
        "location": "Африка",
        "location-precise": "Южная часть Африки"
    },
    {
        "name": "Ливан",
        "fullname": "Ливанская Республика",
        "english": "Lebanon",
        "alpha2": "LB",
        "alpha3": "LBN",
        "iso": "422",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Ливийская Арабская Джамахирия",
        "fullname": "Социалистическая Народная Ливийская Арабская Джамахирия",
        "english": "Libyan Arab Jamahiriya",
        "alpha2": "LY",
        "alpha3": "LBY",
        "iso": "434",
        "location": "Африка",
        "location-precise": "Северная Африка"
    },
    {
        "name": "Либерия",
        "fullname": "Республика Либерия",
        "english": "Liberia",
        "alpha2": "LR",
        "alpha3": "LBR",
        "iso": "430",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Лихтенштейн",
        "fullname": "Княжество Лихтенштейн",
        "english": "Liechtenstein",
        "alpha2": "LI",
        "alpha3": "LIE",
        "iso": "438",
        "location": "Европа",
        "location-precise": "Западная Европа"
    },
    {
        "name": "Литва",
        "fullname": "Литовская Республика",
        "english": "Lithuania",
        "alpha2": "LT",
        "alpha3": "LTU",
        "iso": "440",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Люксембург",
        "fullname": "Великое Герцогство Люксембург",
        "english": "Luxembourg",
        "alpha2": "LU",
        "alpha3": "LUX",
        "iso": "442",
        "location": "Европа",
        "location-precise": "Западная Европа"
    },
    {
        "name": "Маврикий",
        "fullname": "Республика Маврикий",
        "english": "Mauritius",
        "alpha2": "MU",
        "alpha3": "MUS",
        "iso": "480",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Мавритания",
        "fullname": "Исламская Республика Мавритания",
        "english": "Mauritania",
        "alpha2": "MR",
        "alpha3": "MRT",
        "iso": "478",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Мадагаскар",
        "fullname": "Республика Мадагаскар",
        "english": "Madagascar",
        "alpha2": "MG",
        "alpha3": "MDG",
        "iso": "450",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Майотта",
        "fullname": " ",
        "english": "Mayotte",
        "alpha2": "YT",
        "alpha3": "MYT",
        "iso": "175",
        "location": "Африка",
        "location-precise": "Южная часть Африки"
    },
    {
        "name": "Макао",
        "fullname": "Специальный административный регион Китая Макао",
        "english": "Macao",
        "alpha2": "MO",
        "alpha3": "MAC",
        "iso": "446",
        "location": "Азия",
        "location-precise": "Восточная Азия"
    },
    {
        "name": "Малави",
        "fullname": "Республика Малави",
        "english": "Malawi",
        "alpha2": "MW",
        "alpha3": "MWI",
        "iso": "454",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Малайзия",
        "fullname": " ",
        "english": "Malaysia",
        "alpha2": "MY",
        "alpha3": "MYS",
        "iso": "458",
        "location": "Азия",
        "location-precise": "Юго-Восточная Азия"
    },
    {
        "name": "Мали",
        "fullname": "Республика Мали",
        "english": "Mali",
        "alpha2": "ML",
        "alpha3": "MLI",
        "iso": "466",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Малые Тихоокеанские отдаленные острова Соединенных Штатов",
        "fullname": " ",
        "english": "United States Minor Outlying Islands",
        "alpha2": "UM",
        "alpha3": "UMI",
        "iso": "581",
        "location": "Океания",
        "location-precise": "Индийский океан"
    },
    {
        "name": "Мальдивы",
        "fullname": "Мальдивская Республика",
        "english": "Maldives",
        "alpha2": "MV",
        "alpha3": "MDV",
        "iso": "462",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Мальта",
        "fullname": "Республика Мальта",
        "english": "Malta",
        "alpha2": "MT",
        "alpha3": "MLT",
        "iso": "470",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Марокко",
        "fullname": "Королевство Марокко",
        "english": "Morocco",
        "alpha2": "MA",
        "alpha3": "MAR",
        "iso": "504",
        "location": "Африка",
        "location-precise": "Северная Африка"
    },
    {
        "name": "Мартиника",
        "fullname": " ",
        "english": "Martinique",
        "alpha2": "MQ",
        "alpha3": "MTQ",
        "iso": "474",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Маршалловы острова",
        "fullname": "Республика Маршалловы острова",
        "english": "Marshall Islands",
        "alpha2": "MH",
        "alpha3": "MHL",
        "iso": "584",
        "location": "Океания",
        "location-precise": "Микронезия"
    },
    {
        "name": "Мексика",
        "fullname": "Мексиканские Соединенные Штаты",
        "english": "Mexico",
        "alpha2": "MX",
        "alpha3": "MEX",
        "iso": "484",
        "location": "Америка",
        "location-precise": "Центральная Америка"
    },
    {
        "name": "Микронезия, Федеративные Штаты",
        "fullname": "Федеративные штаты Микронезии",
        "english": "Micronesia, Federated States of",
        "alpha2": "FM",
        "alpha3": "FSM",
        "iso": "583",
        "location": "Океания",
        "location-precise": "Микронезия"
    },
    {
        "name": "Мозамбик",
        "fullname": "Республика Мозамбик",
        "english": "Mozambique",
        "alpha2": "MZ",
        "alpha3": "MOZ",
        "iso": "508",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Молдова, Республика",
        "fullname": "Республика Молдова",
        "english": "Moldova",
        "alpha2": "MD",
        "alpha3": "MDA",
        "iso": "498",
        "location": "Европа",
        "location-precise": "Восточная Европа"
    },
    {
        "name": "Монако",
        "fullname": "Княжество Монако",
        "english": "Monaco",
        "alpha2": "MC",
        "alpha3": "MCO",
        "iso": "492",
        "location": "Европа",
        "location-precise": "Западная Европа"
    },
    {
        "name": "Монголия",
        "fullname": " ",
        "english": "Mongolia",
        "alpha2": "MN",
        "alpha3": "MNG",
        "iso": "496",
        "location": "Азия",
        "location-precise": "Восточная Азия"
    },
    {
        "name": "Монтсеррат",
        "fullname": " ",
        "english": "Montserrat",
        "alpha2": "MS",
        "alpha3": "MSR",
        "iso": "500",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Мьянма",
        "fullname": "Союз Мьянма",
        "english": "Myanmar",
        "alpha2": "MM",
        "alpha3": "MMR",
        "iso": "104",
        "location": "Азия",
        "location-precise": "Юго-Восточная Азия"
    },
    {
        "name": "Намибия",
        "fullname": "Республика Намибия",
        "english": "Namibia",
        "alpha2": "NA",
        "alpha3": "NAM",
        "iso": "516",
        "location": "Африка",
        "location-precise": "Южная часть Африки"
    },
    {
        "name": "Науру",
        "fullname": "Республика Науру",
        "english": "Nauru",
        "alpha2": "NR",
        "alpha3": "NRU",
        "iso": "520",
        "location": "Океания",
        "location-precise": "Микронезия"
    },
    {
        "name": "Непал",
        "fullname": "Федеративная Демократическая Республика Непал",
        "english": "Nepal",
        "alpha2": "NP",
        "alpha3": "NPL",
        "iso": "524",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Нигер",
        "fullname": "Республика Нигер",
        "english": "Niger",
        "alpha2": "NE",
        "alpha3": "NER",
        "iso": "562",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Нигерия",
        "fullname": "Федеративная Республика Нигерия",
        "english": "Nigeria",
        "alpha2": "NG",
        "alpha3": "NGA",
        "iso": "566",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Нидерланды",
        "fullname": "Королевство Нидерландов",
        "english": "Netherlands",
        "alpha2": "NL",
        "alpha3": "NLD",
        "iso": "528",
        "location": "Европа",
        "location-precise": "Западная Европа"
    },
    {
        "name": "Никарагуа",
        "fullname": "Республика Никарагуа",
        "english": "Nicaragua",
        "alpha2": "NI",
        "alpha3": "NIC",
        "iso": "558",
        "location": "Америка",
        "location-precise": "Центральная Америка"
    },
    {
        "name": "Ниуэ",
        "fullname": "Республика Ниуэ",
        "english": "Niue",
        "alpha2": "NU",
        "alpha3": "NIU",
        "iso": "570",
        "location": "Океания",
        "location-precise": "Полинезия"
    },
    {
        "name": "Новая Зеландия",
        "fullname": " ",
        "english": "New Zealand",
        "alpha2": "NZ",
        "alpha3": "NZL",
        "iso": "554",
        "location": "Океания",
        "location-precise": "Австралия и Новая Зеландия"
    },
    {
        "name": "Новая Каледония",
        "fullname": " ",
        "english": "New Caledonia",
        "alpha2": "NC",
        "alpha3": "NCL",
        "iso": "540",
        "location": "Океания",
        "location-precise": "Меланезия"
    },
    {
        "name": "Норвегия",
        "fullname": "Королевство Норвегия",
        "english": "Norway",
        "alpha2": "NO",
        "alpha3": "NOR",
        "iso": "578",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Объединенные Арабские Эмираты",
        "fullname": " ",
        "english": "United Arab Emirates",
        "alpha2": "AE",
        "alpha3": "ARE",
        "iso": "784",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Оман",
        "fullname": "Султанат Оман",
        "english": "Oman",
        "alpha2": "OM",
        "alpha3": "OMN",
        "iso": "512",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Остров Буве",
        "fullname": " ",
        "english": "Bouvet Island",
        "alpha2": "BV",
        "alpha3": "BVT",
        "iso": "074",
        "location": " ",
        "location-precise": "Южный океан"
    },
    {
        "name": "Остров Мэн",
        "fullname": " ",
        "english": "Isle of Man",
        "alpha2": "IM",
        "alpha3": "IMN",
        "iso": "833",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Остров Норфолк",
        "fullname": " ",
        "english": "Norfolk Island",
        "alpha2": "NF",
        "alpha3": "NFK",
        "iso": "574",
        "location": "Океания",
        "location-precise": "Австралия и Новая Зеландия"
    },
    {
        "name": "Остров Рождества",
        "fullname": " ",
        "english": "Christmas Island",
        "alpha2": "CX",
        "alpha3": "CXR",
        "iso": "162",
        "location": "Азия",
        "location-precise": "Индийский океан"
    },
    {
        "name": "Остров Херд и острова Макдональд",
        "fullname": " ",
        "english": "Heard Island and McDonald Islands",
        "alpha2": "HM",
        "alpha3": "HMD",
        "iso": "334",
        "location": " ",
        "location-precise": "Индийский океан"
    },
    {
        "name": "Острова Кайман",
        "fullname": " ",
        "english": "Cayman Islands",
        "alpha2": "KY",
        "alpha3": "CYM",
        "iso": "136",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Острова Кука",
        "fullname": " ",
        "english": "Cook Islands",
        "alpha2": "CK",
        "alpha3": "COK",
        "iso": "184",
        "location": "Океания",
        "location-precise": "Полинезия"
    },
    {
        "name": "Острова Теркс и Кайкос",
        "fullname": " ",
        "english": "Turks and Caicos Islands",
        "alpha2": "TC",
        "alpha3": "TCA",
        "iso": "796",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Пакистан",
        "fullname": "Исламская Республика Пакистан",
        "english": "Pakistan",
        "alpha2": "PK",
        "alpha3": "PAK",
        "iso": "586",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Палау",
        "fullname": "Республика Палау",
        "english": "Palau",
        "alpha2": "PW",
        "alpha3": "PLW",
        "iso": "585",
        "location": "Океания",
        "location-precise": "Микронезия"
    },
    {
        "name": "Палестинская территория, оккупированная",
        "fullname": "Оккупированная Палестинская территория",
        "english": "Palestinian Territory, Occupied",
        "alpha2": "PS",
        "alpha3": "PSE",
        "iso": "275",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Панама",
        "fullname": "Республика Панама",
        "english": "Panama",
        "alpha2": "PA",
        "alpha3": "PAN",
        "iso": "591",
        "location": "Америка",
        "location-precise": "Центральная Америка"
    },
    {
        "name": "Папский Престол (Государство \u2014 город Ватикан)",
        "fullname": " ",
        "english": "Holy See (Vatican City State)",
        "alpha2": "VA",
        "alpha3": "VAT",
        "iso": "336",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Папуа-Новая Гвинея",
        "fullname": " ",
        "english": "Papua New Guinea",
        "alpha2": "PG",
        "alpha3": "PNG",
        "iso": "598",
        "location": "Океания",
        "location-precise": "Меланезия"
    },
    {
        "name": "Парагвай",
        "fullname": "Республика Парагвай",
        "english": "Paraguay",
        "alpha2": "PY",
        "alpha3": "PRY",
        "iso": "600",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Перу",
        "fullname": "Республика Перу",
        "english": "Peru",
        "alpha2": "PE",
        "alpha3": "PER",
        "iso": "604",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Питкерн",
        "fullname": " ",
        "english": "Pitcairn",
        "alpha2": "PN",
        "alpha3": "PCN",
        "iso": "612",
        "location": "Океания",
        "location-precise": "Полинезия"
    },
    {
        "name": "Польша",
        "fullname": "Республика Польша",
        "english": "Poland",
        "alpha2": "PL",
        "alpha3": "POL",
        "iso": "616",
        "location": "Европа",
        "location-precise": "Восточная Европа"
    },
    {
        "name": "Португалия",
        "fullname": "Португальская Республика",
        "english": "Portugal",
        "alpha2": "PT",
        "alpha3": "PRT",
        "iso": "620",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Пуэрто-Рико",
        "fullname": " ",
        "english": "Puerto Rico",
        "alpha2": "PR",
        "alpha3": "PRI",
        "iso": "630",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Республика Македония",
        "fullname": " ",
        "english": "Macedonia, The Former Yugoslav Republic Of",
        "alpha2": "MK",
        "alpha3": "MKD",
        "iso": "807",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Реюньон",
        "fullname": " ",
        "english": "Reunion",
        "alpha2": "RE",
        "alpha3": "REU",
        "iso": "638",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Россия",
        "fullname": "Российская Федерация",
        "english": "Russian Federation",
        "alpha2": "RU",
        "alpha3": "RUS",
        "iso": "643",
        "location": "Европа",
        "location-precise": "Восточная Европа"
    },
    {
        "name": "Руанда",
        "fullname": "Руандийская Республика",
        "english": "Rwanda",
        "alpha2": "RW",
        "alpha3": "RWA",
        "iso": "646",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Румыния",
        "fullname": " ",
        "english": "Romania",
        "alpha2": "RO",
        "alpha3": "ROU",
        "iso": "642",
        "location": "Европа",
        "location-precise": "Восточная Европа"
    },
    {
        "name": "Самоа",
        "fullname": "Независимое Государство Самоа",
        "english": "Samoa",
        "alpha2": "WS",
        "alpha3": "WSM",
        "iso": "882",
        "location": "Океания",
        "location-precise": "Полинезия"
    },
    {
        "name": "Сан-Марино",
        "fullname": "Республика Сан-Марино",
        "english": "San Marino",
        "alpha2": "SM",
        "alpha3": "SMR",
        "iso": "674",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Сан-Томе и Принсипи",
        "fullname": "Демократическая Республика Сан-Томе и Принсипи",
        "english": "Sao Tome and Principe",
        "alpha2": "ST",
        "alpha3": "STP",
        "iso": "678",
        "location": "Африка",
        "location-precise": "Центральная Африка"
    },
    {
        "name": "Саудовская Аравия",
        "fullname": "Королевство Саудовская Аравия",
        "english": "Saudi Arabia",
        "alpha2": "SA",
        "alpha3": "SAU",
        "iso": "682",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Святая Елена, Остров вознесения, Тристан-да-Кунья",
        "fullname": " ",
        "english": "Saint Helena, Ascension And Tristan Da Cunha",
        "alpha2": "SH",
        "alpha3": "SHN",
        "iso": "654",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Северные Марианские острова",
        "fullname": "Содружество Северных Марианских островов",
        "english": "Northern Mariana Islands",
        "alpha2": "MP",
        "alpha3": "MNP",
        "iso": "580",
        "location": "Океания",
        "location-precise": "Микронезия"
    },
    {
        "name": "Сен-Бартельми",
        "fullname": " ",
        "english": "Saint Barthélemy",
        "alpha2": "BL",
        "alpha3": "BLM",
        "iso": "652",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Сен-Мартен",
        "fullname": " ",
        "english": "Saint Martin (French Part)",
        "alpha2": "MF",
        "alpha3": "MAF",
        "iso": "663",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Сенегал",
        "fullname": "Республика Сенегал",
        "english": "Senegal",
        "alpha2": "SN",
        "alpha3": "SEN",
        "iso": "686",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Сент-Винсент и Гренадины",
        "fullname": " ",
        "english": "Saint Vincent and the Grenadines",
        "alpha2": "VC",
        "alpha3": "VCT",
        "iso": "670",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Сент-Люсия",
        "fullname": " ",
        "english": "Saint Lucia",
        "alpha2": "LC",
        "alpha3": "LCA",
        "iso": "662",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Сент-Китс и Невис",
        "fullname": " ",
        "english": "Saint Kitts and Nevis",
        "alpha2": "KN",
        "alpha3": "KNA",
        "iso": "659",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Сент-Пьер и Микелон",
        "fullname": " ",
        "english": "Saint Pierre and Miquelon",
        "alpha2": "PM",
        "alpha3": "SPM",
        "iso": "666",
        "location": "Америка",
        "location-precise": "Северная Америка"
    },
    {
        "name": "Сербия",
        "fullname": "Республика Сербия",
        "english": "Serbia",
        "alpha2": "RS",
        "alpha3": "SRB",
        "iso": "688",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Сейшелы",
        "fullname": "Республика Сейшелы",
        "english": "Seychelles",
        "alpha2": "SC",
        "alpha3": "SYC",
        "iso": "690",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Сингапур",
        "fullname": "Республика Сингапур",
        "english": "Singapore",
        "alpha2": "SG",
        "alpha3": "SGP",
        "iso": "702",
        "location": "Азия",
        "location-precise": "Юго-Восточная Азия"
    },
    {
        "name": "Синт-Мартен",
        "fullname": " ",
        "english": "Sint Maarten",
        "alpha2": "SX",
        "alpha3": "SXM",
        "iso": "534",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Сирийская Арабская Республика",
        "fullname": " ",
        "english": "Syrian Arab Republic",
        "alpha2": "SY",
        "alpha3": "SYR",
        "iso": "760",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Словакия",
        "fullname": "Словацкая Республика",
        "english": "Slovakia",
        "alpha2": "SK",
        "alpha3": "SVK",
        "iso": "703",
        "location": "Европа",
        "location-precise": "Восточная Европа"
    },
    {
        "name": "Словения",
        "fullname": "Республика Словения",
        "english": "Slovenia",
        "alpha2": "SI",
        "alpha3": "SVN",
        "iso": "705",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Соединенное Королевство",
        "fullname": "Соединенное Королевство Великобритании и Северной Ирландии",
        "english": "United Kingdom",
        "alpha2": "GB",
        "alpha3": "GBR",
        "iso": "826",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Соединенные Штаты",
        "fullname": "Соединенные Штаты Америки",
        "english": "United States",
        "alpha2": "US",
        "alpha3": "USA",
        "iso": "840",
        "location": "Америка",
        "location-precise": "Северная Америка"
    },
    {
        "name": "Соломоновы острова",
        "fullname": " ",
        "english": "Solomon Islands",
        "alpha2": "SB",
        "alpha3": "SLB",
        "iso": "090",
        "location": "Океания",
        "location-precise": "Меланезия"
    },
    {
        "name": "Сомали",
        "fullname": "Сомалийская Республика",
        "english": "Somalia",
        "alpha2": "SO",
        "alpha3": "SOM",
        "iso": "706",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Судан",
        "fullname": "Республика Судан",
        "english": "Sudan",
        "alpha2": "SD",
        "alpha3": "SDN",
        "iso": "729",
        "location": "Африка",
        "location-precise": "Северная Африка"
    },
    {
        "name": "Суринам",
        "fullname": "Республика Суринам",
        "english": "Suriname",
        "alpha2": "SR",
        "alpha3": "SUR",
        "iso": "740",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Сьерра-Леоне",
        "fullname": "Республика Сьерра-Леоне",
        "english": "Sierra Leone",
        "alpha2": "SL",
        "alpha3": "SLE",
        "iso": "694",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Таджикистан",
        "fullname": "Республика Таджикистан",
        "english": "Tajikistan",
        "alpha2": "TJ",
        "alpha3": "TJK",
        "iso": "762",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Таиланд",
        "fullname": "Королевство Таиланд",
        "english": "Thailand",
        "alpha2": "TH",
        "alpha3": "THA",
        "iso": "764",
        "location": "Азия",
        "location-precise": "Юго-Восточная Азия"
    },
    {
        "name": "Тайвань (Китай)",
        "fullname": " ",
        "english": "Taiwan, Province of China",
        "alpha2": "TW",
        "alpha3": "TWN",
        "iso": "158",
        "location": "Азия",
        "location-precise": "Восточная Азия"
    },
    {
        "name": "Танзания, Объединенная Республика",
        "fullname": "Объединенная Республика Танзания",
        "english": "Tanzania, United Republic Of",
        "alpha2": "TZ",
        "alpha3": "TZA",
        "iso": "834",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Тимор-Лесте",
        "fullname": "Демократическая Республика Тимор-Лесте",
        "english": "Timor-Leste",
        "alpha2": "TL",
        "alpha3": "TLS",
        "iso": "626",
        "location": "Азия",
        "location-precise": "Юго-Восточная Азия"
    },
    {
        "name": "Того",
        "fullname": "Тоголезская Республика",
        "english": "Togo",
        "alpha2": "TG",
        "alpha3": "TGO",
        "iso": "768",
        "location": "Африка",
        "location-precise": "Западная Африка"
    },
    {
        "name": "Токелау",
        "fullname": " ",
        "english": "Tokelau",
        "alpha2": "TK",
        "alpha3": "TKL",
        "iso": "772",
        "location": "Океания",
        "location-precise": "Полинезия"
    },
    {
        "name": "Тонга",
        "fullname": "Королевство Тонга",
        "english": "Tonga",
        "alpha2": "TO",
        "alpha3": "TON",
        "iso": "776",
        "location": "Океания",
        "location-precise": "Полинезия"
    },
    {
        "name": "Тринидад и Тобаго",
        "fullname": "Республика Тринидад и Тобаго",
        "english": "Trinidad and Tobago",
        "alpha2": "TT",
        "alpha3": "TTO",
        "iso": "780",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Тувалу",
        "fullname": " ",
        "english": "Tuvalu",
        "alpha2": "TV",
        "alpha3": "TUV",
        "iso": "798",
        "location": "Океания",
        "location-precise": "Полинезия"
    },
    {
        "name": "Тунис",
        "fullname": "Тунисская Республика",
        "english": "Tunisia",
        "alpha2": "TN",
        "alpha3": "TUN",
        "iso": "788",
        "location": "Африка",
        "location-precise": "Северная Африка"
    },
    {
        "name": "Туркмения",
        "fullname": "Туркменистан",
        "english": "Turkmenistan",
        "alpha2": "TM",
        "alpha3": "TKM",
        "iso": "795",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Турция",
        "fullname": "Турецкая Республика",
        "english": "Turkey",
        "alpha2": "TR",
        "alpha3": "TUR",
        "iso": "792",
        "location": "Азия",
        "location-precise": "Западная Азия"
    },
    {
        "name": "Уганда",
        "fullname": "Республика Уганда",
        "english": "Uganda",
        "alpha2": "UG",
        "alpha3": "UGA",
        "iso": "800",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Узбекистан",
        "fullname": "Республика Узбекистан",
        "english": "Uzbekistan",
        "alpha2": "UZ",
        "alpha3": "UZB",
        "iso": "860",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Украина",
        "fullname": " ",
        "english": "Ukraine",
        "alpha2": "UA",
        "alpha3": "UKR",
        "iso": "804",
        "location": "Европа",
        "location-precise": "Восточная Европа"
    },
    {
        "name": "Уоллис и Футуна",
        "fullname": " ",
        "english": "Wallis and Futuna",
        "alpha2": "WF",
        "alpha3": "WLF",
        "iso": "876",
        "location": "Океания",
        "location-precise": "Полинезия"
    },
    {
        "name": "Уругвай",
        "fullname": "Восточная Республика Уругвай",
        "english": "Uruguay",
        "alpha2": "UY",
        "alpha3": "URY",
        "iso": "858",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Фарерские острова",
        "fullname": " ",
        "english": "Faroe Islands",
        "alpha2": "FO",
        "alpha3": "FRO",
        "iso": "234",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Фиджи",
        "fullname": "Республика островов Фиджи",
        "english": "Fiji",
        "alpha2": "FJ",
        "alpha3": "FJI",
        "iso": "242",
        "location": "Океания",
        "location-precise": "Меланезия"
    },
    {
        "name": "Филиппины",
        "fullname": "Республика Филиппины",
        "english": "Philippines",
        "alpha2": "PH",
        "alpha3": "PHL",
        "iso": "608",
        "location": "Азия",
        "location-precise": "Юго-Восточная Азия"
    },
    {
        "name": "Финляндия",
        "fullname": "Финляндская Республика",
        "english": "Finland",
        "alpha2": "FI",
        "alpha3": "FIN",
        "iso": "246",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Фолклендские острова (Мальвинские)",
        "fullname": " ",
        "english": "Falkland Islands (Malvinas)",
        "alpha2": "FK",
        "alpha3": "FLK",
        "iso": "238",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Франция",
        "fullname": "Французская Республика",
        "english": "France",
        "alpha2": "FR",
        "alpha3": "FRA",
        "iso": "250",
        "location": "Европа",
        "location-precise": "Западная Европа"
    },
    {
        "name": "Французская Гвиана",
        "fullname": " ",
        "english": "French Guiana",
        "alpha2": "GF",
        "alpha3": "GUF",
        "iso": "254",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Французская Полинезия",
        "fullname": " ",
        "english": "French Polynesia",
        "alpha2": "PF",
        "alpha3": "PYF",
        "iso": "258",
        "location": "Океания",
        "location-precise": "Полинезия"
    },
    {
        "name": "Французские Южные территории",
        "fullname": " ",
        "english": "French Southern Territories",
        "alpha2": "TF",
        "alpha3": "ATF",
        "iso": "260",
        "location": " ",
        "location-precise": "Индийский океан"
    },
    {
        "name": "Хорватия",
        "fullname": "Республика Хорватия",
        "english": "Croatia",
        "alpha2": "HR",
        "alpha3": "HRV",
        "iso": "191",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Центрально-Африканская Республика",
        "fullname": " ",
        "english": "Central African Republic",
        "alpha2": "CF",
        "alpha3": "CAF",
        "iso": "140",
        "location": "Африка",
        "location-precise": "Центральная Африка"
    },
    {
        "name": "Чад",
        "fullname": "Республика Чад",
        "english": "Chad",
        "alpha2": "TD",
        "alpha3": "TCD",
        "iso": "148",
        "location": "Африка",
        "location-precise": "Центральная Африка"
    },
    {
        "name": "Черногория",
        "fullname": "Республика Черногория",
        "english": "Montenegro",
        "alpha2": "ME",
        "alpha3": "MNE",
        "iso": "499",
        "location": "Европа",
        "location-precise": "Южная Европа"
    },
    {
        "name": "Чешская Республика",
        "fullname": " ",
        "english": "Czech Republic",
        "alpha2": "CZ",
        "alpha3": "CZE",
        "iso": "203",
        "location": "Европа",
        "location-precise": "Восточная Европа"
    },
    {
        "name": "Чили",
        "fullname": "Республика Чили",
        "english": "Chile",
        "alpha2": "CL",
        "alpha3": "CHL",
        "iso": "152",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Швейцария",
        "fullname": "Швейцарская Конфедерация",
        "english": "Switzerland",
        "alpha2": "CH",
        "alpha3": "CHE",
        "iso": "756",
        "location": "Европа",
        "location-precise": "Западная Европа"
    },
    {
        "name": "Швеция",
        "fullname": "Королевство Швеция",
        "english": "Sweden",
        "alpha2": "SE",
        "alpha3": "SWE",
        "iso": "752",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Шпицберген и Ян Майен",
        "fullname": " ",
        "english": "Svalbard and Jan Mayen",
        "alpha2": "SJ",
        "alpha3": "SJM",
        "iso": "744",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Шри-Ланка",
        "fullname": "Демократическая Социалистическая Республика Шри-Ланка",
        "english": "Sri Lanka",
        "alpha2": "LK",
        "alpha3": "LKA",
        "iso": "144",
        "location": "Азия",
        "location-precise": "Южная часть Центральной Азии"
    },
    {
        "name": "Эквадор",
        "fullname": "Республика Эквадор",
        "english": "Ecuador",
        "alpha2": "EC",
        "alpha3": "ECU",
        "iso": "218",
        "location": "Америка",
        "location-precise": "Южная Америка"
    },
    {
        "name": "Экваториальная Гвинея",
        "fullname": "Республика Экваториальная Гвинея",
        "english": "Equatorial Guinea",
        "alpha2": "GQ",
        "alpha3": "GNQ",
        "iso": "226",
        "location": "Африка",
        "location-precise": "Центральная Африка"
    },
    {
        "name": "Эландские острова",
        "fullname": " ",
        "english": "Åland Islands",
        "alpha2": "AX",
        "alpha3": "ALA",
        "iso": "248",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Эль-Сальвадор",
        "fullname": "Республика Эль-Сальвадор",
        "english": "El Salvador",
        "alpha2": "SV",
        "alpha3": "SLV",
        "iso": "222",
        "location": "Америка",
        "location-precise": "Центральная Америка"
    },
    {
        "name": "Эритрея",
        "fullname": " ",
        "english": "Eritrea",
        "alpha2": "ER",
        "alpha3": "ERI",
        "iso": "232",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Эсватини",
        "fullname": "Королевство Эсватини",
        "english": "Eswatini",
        "alpha2": "SZ",
        "alpha3": "SWZ",
        "iso": "748",
        "location": "Африка",
        "location-precise": "Южная часть Африки"
    },
    {
        "name": "Эстония",
        "fullname": "Эстонская Республика",
        "english": "Estonia",
        "alpha2": "EE",
        "alpha3": "EST",
        "iso": "233",
        "location": "Европа",
        "location-precise": "Северная Европа"
    },
    {
        "name": "Эфиопия",
        "fullname": "Федеративная Демократическая Республика Эфиопия",
        "english": "Ethiopia",
        "alpha2": "ET",
        "alpha3": "ETH",
        "iso": "231",
        "location": "Африка",
        "location-precise": "Восточная Африка"
    },
    {
        "name": "Южная Африка",
        "fullname": "Южно-Африканская Республика",
        "english": "South Africa",
        "alpha2": "ZA",
        "alpha3": "ZAF",
        "iso": "710",
        "location": "Африка",
        "location-precise": "Южная часть Африки"
    },
    {
        "name": "Южная Джорджия и Южные Сандвичевы острова",
        "fullname": " ",
        "english": "South Georgia and the South Sandwich Islands",
        "alpha2": "GS",
        "alpha3": "SGS",
        "iso": "239",
        "location": " ",
        "location-precise": "Южный океан"
    },
    {
        "name": "Южная Осетия",
        "fullname": "Республика Южная Осетия",
        "english": "South Ossetia",
        "alpha2": "OS",
        "alpha3": "OST",
        "iso": "896",
        "location": "Азия",
        "location-precise": "Закавказье"
    },
    {
        "name": "Южный Судан",
        "fullname": " ",
        "english": "South Sudan",
        "alpha2": "SS",
        "alpha3": "SSD",
        "iso": "728",
        "location": "Африка",
        "location-precise": "Северная Африка"
    },
    {
        "name": "Ямайка",
        "fullname": " ",
        "english": "Jamaica",
        "alpha2": "JM",
        "alpha3": "JAM",
        "iso": "388",
        "location": "Америка",
        "location-precise": "Карибский бассейн"
    },
    {
        "name": "Япония",
        "fullname": " ",
        "english": "Japan",
        "alpha2": "JP",
        "alpha3": "JPN",
        "iso": "392",
        "location": "Азия",
        "location-precise": "Восточная Азия"
    }
]
export default countries;