<template>
  <modal @close="close()" title="Сменить тариф">
    <h2>Нет списка тарифов</h2>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import CRadio from "@/components/Form/c-radio.vue";

export default {
  data() {
    return {
      tariffs: {},
    };
  },
  components: {
    Modal,
    CRadio,
  },
  mounted() {
    // this.$apiClient.getTariffs().then((resp) => {
    //   this.tariffs = resp;
    // });
  },
  methods: {
    close() {
      this.$emit("close");
    },
    //
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.close-icon {
  border: none;
  cursor: pointer;
  font-weight: bold;
  fill: rgba(167, 177, 192, 1);
  background: transparent;
  position: absolute;
  right: -100px;
}
h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
</style>
