<template>
  <div class="container faq-container">
    <div class="head">
      <h1>FAQ</h1>
    </div>

    <section class="questions-block" v-for="faq_sec of FAQContent" :key="faq_sec.title">
      <h2>{{ faq_sec.title }}</h2>
      <faq-item v-for="faq of faq_sec.items" :key="faq.title" :id="faq.title">
        <template #title> {{ faq.title }} </template>
        <template #content>
          {{ faq.content }}
        </template>
      </faq-item>
    </section>
  </div>
</template>

<script>
import FaqItem from "@/components/faq-item.vue";

let lorem =
  "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit dignissimos necessitatibus rem, explicabo sapiente pariatur quae ipsam ipsum numquam qui distinctio consectetur expedita itaque ducimus alias voluptatem perspiciatis tempora modi!";

let FAQContent = [
  {
    title: "Раздел 1",
    items: [
      { title: "Заголовок 1", content: lorem },
      { title: "Заголовок 2", content: lorem },
      { title: "Заголовок 3", content: lorem },
      { title: "Заголовок 4", content: lorem },
    ],
  },
  {
    title: "Раздел 2",
    items: [
      { title: "Заголовок 11", content: lorem },
      { title: "Заголовок 12", content: lorem },
      { title: "Заголовок 13", content: lorem },
      { title: "Заголовок 14", content: lorem },
    ],
  },
];

export default {
  components: { FaqItem },
  data() {
    return {
      FAQContent: FAQContent,
    };
  },
};
</script>

<style lang="scss" scoped>
.faq-container {
  color: #2d4350;
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 3rem;
  }
}
.questions-block {
  max-width: 762px;
}
</style>
