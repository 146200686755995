<template>
  <div class="container campaigns-container">
    <div class="head">
      <div class="actions-head-left">
        <h1>Кампании</h1>
        <span class="refresh" @click="loadCompanies()">
          <svg
            v-svg
            symbol="icon_refresh"
            size="0 0 20 20"
            role="presentation"
            class="c-icons edit"
          ></svg>
        </span>
      </div>
      <c-button
        type="primary"
        size="large"
        @click="showModal()"
        icon="icon_plus"
        iconSize="0 0 24 24"
      >
        Создать кампанию
      </c-button>
    </div>

    <c-table
      :loading="StatusTable"
      :columns="columns"
      :dataSrc="filteredList"
      cheked
      datepicker
      :dataPerPage.sync="defaultRows"
      v-if="dataTable"
      @update:dataSrc="dataTable = $event"
      @search="search = $event"
      id="campaigns"
      attrName="name"
    >
      <span slot="total">
        <svg
          v-svg
          symbol="icon_users"
          size="0 0 20 20"
          role="presentation"
          fill="red"
          class="c-icons users"
        ></svg>
      </span>

      <span slot="black">
        <svg
          v-svg
          symbol="icon_user-check"
          size="0 0 20 20"
          role="presentation"
          class="c-icons user-check"
        ></svg>
      </span>
      <span slot="white">
        <svg
          v-svg
          symbol="icon_user_x"
          size="0 0 20 20"
          role="presentation"
          class="c-icons user-x"
        ></svg>
      </span>
      <span slot="conversion">
        <svg
          v-svg
          symbol="icon_percent"
          size="0 0 20 20"
          role="presentation"
          class="c-icons percent"
        ></svg>
      </span>
      <span slot="name" class="name">
        <span> Имя </span>
        <span class="subname"> id </span>
      </span>

      <span class="t total" slot="traffic_page"> Traffic Page </span>
      <span class="t black" slot="black_page"> Black Page </span>
      <span class="t white" slot="white_page"> White Page </span>

      <span slot="action" slot-scope="record">
        <div class="action" style="display: flex; flex-direction: row">
          <span
            @click="action('Stat', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_bar_chart"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
          <span
            @click="action('Copy', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_copy"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
          <span
            @click="action('Edit', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_edit"
              size="0 0 20 20"
              role="presentation"
              class="c-icons edit"
            ></svg>
          </span>
          <span
            @click="action('Download', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_download"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
          <span
            @click="action('Delete', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_trash_2"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
          <span class="handle" style="margin-left: 20px; margin-top: 10px">
            <svg
              v-svg
              symbol="icon_drag_and_drop"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
        </div>
      </span>

      <span slot="status" slot-scope="record">
        <div
          class="status"
          style="display: flex; flex-direction: row; align-items: center"
        >
          <span class="circle-status" v-if="record['record']['status'] == '1'">
            <svg
              width="10"
              height="10"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="4" cy="4" r="4" fill="#299F15" />
            </svg>
          </span>
          <span class="circle-status" v-else-if="'2'">
            <svg
              width="10"
              height="10"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="4" cy="4" r="4" fill="#A7B1C0" />
            </svg>
          </span>
          <span
            v-if="record['record']['status'] == '1'"
            class="click-play"
            style="cursor: pointer"
          >
            <svg
              v-svg
              symbol="pause"
              size="0 0 24 24"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>

          <span v-else-if="'2'" class="click-play" style="cursor: pointer">
            <svg
              v-if="darkMode"
              v-svg
              symbol="play_dark"
              size="0 0 24 24"
              role="presentation"
              class="c-icons"
            ></svg>
            <svg
              v-else
              v-svg
              symbol="play"
              size="0 0 24 24"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
        </div>
      </span>

      <span slot="names" slot-scope="record">
        <div class="campaign-name">
          <span class="cname"> {{ record["record"]["name"] }}</span>
          <span class="csubname"> {{ record["record"]["sub"] }}</span>
        </div>
      </span>

      <span class="table-stat total" slot="total_row" slot-scope="record">
        {{ record["record"]["stat"]["total"] || "0" }}
      </span>

      <span class="table-stat black" slot="black_row" slot-scope="record">
        {{ record["record"]["stat"]["black"] || "0" }}
      </span>

      <span class="table-stat white" slot="white_row" slot-scope="record">
        {{ record["record"]["stat"]["white"] || "0" }}
      </span>

      <span class="table-stat percent" slot="percent_row" slot-scope="record">
        {{ (record["record"]["stat"]["percent"] || "0") + "%" }}
      </span>
      <!--  style="resize: none" -->
      <!-- eslint-disable -->
      <span
        class="textarea-overlay-container"
        slot="traffic_page_row"
        slot-scope="text, record"
      >
        <textarea-over class="table-input textarea-overlay">
          {{ text.trafic }}
        </textarea-over>
      </span>

      <span class="textarea-overlay-container" slot="white_page_row" slot-scope="record">
        <textarea-over class="table-input textarea-overlay">{{
          record["record"]["white_page"]
        }}</textarea-over>
      </span>

      <span class="textarea-overlay-container" slot="black_page_row" slot-scope="record">
        <textarea-over class="table-input textarea-overlay">{{
          record["record"]["black_page"]
        }}</textarea-over>
      </span>
    </c-table>

    <loading-indicator class="loading-spaced" v-else></loading-indicator>

    <create-campaign
      v-show="isModalVisible"
      @close="closeModal"
      @campaignCreated="onCampaignCreated()"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    width: "100",
    scopedSlots: { customRender: "status" },
  },
  {
    dataIndex: "name",
    key: "name",
    slots: { title: "name" },
    width: "190",
    scopedSlots: { customRender: "names" },
  },
  {
    dataIndex: "traffic_page",
    key: "traffic_page",
    width: "200",
    slots: { title: "traffic_page" },
    scopedSlots: { customRender: "traffic_page_row" },
  },
  {
    dataIndex: "black_page",
    key: "blacks",
    width: "200",
    slots: { title: "black_page" },
    scopedSlots: { customRender: "black_page_row" },
  },
  {
    dataIndex: "white_page",
    key: "whites",
    width: "200",
    slots: { title: "white_page" },
    scopedSlots: { customRender: "white_page_row" },
  },
  {
    dataIndex: "stat",
    key: "stat",
    align: "center",
    width: "60",
    slots: { title: "total" },
    scopedSlots: { customRender: "total_row" },
  },
  {
    dataIndex: "stat",
    key: "stat",
    align: "center",
    width: "60",
    slots: { title: "black" },
    scopedSlots: { customRender: "black_row" },
  },
  {
    dataIndex: "stat",
    key: "stat",
    align: "center",
    width: "60",
    slots: { title: "white" },
    scopedSlots: { customRender: "white_row" },
  },
  {
    dataIndex: "stat",
    key: "stat",
    width: "60",
    slots: { title: "conversion" },
    scopedSlots: { customRender: "percent_row" },
  },
  {
    title: "Действия",
    key: "action",
    width: "260",
    scopedSlots: { customRender: "action" },
  },
];

import CreateCampaign from "../Modules/CreateCampaign.vue";
import CTable from "../Table/c-table.vue";
import CSearch from "../Form/c-search.vue";
import CInput from "../Form/c-input.vue";
import CDropselect from "../Form/с-dropselect.vue";
import CTooltip from "../Tooltip/c-tooltip.vue";
import CDatepicker from "../Datepicker/datepicker.vue";
import CButton from "../Button/c-button.vue";
import loadingIndicator from "@/components/Loading/loading-indicator.vue";
import textareaOver from "@/components/Form/textarea-overflow.vue";

let defaultCompanyData = {
  whites: "0",
  stat: {},
};

export default {
  data() {
    return {
      columns,
      search: "",
      dataTable: [],
      selectedAction: "",
      defaultRows: 5,
      selectedDate: [new Date(), new Date()],
      isModalVisible: false,
    };
  },
  components: {
    CTable,
    CInput,
    CDropselect,
    CTooltip,
    CDatepicker,
    CButton,
    CSearch,
    CreateCampaign,
    loadingIndicator,
    textareaOver,
  },
  mounted() {
    // this.$store.dispatch("getCampaigns");
    // this.dataTable = this.$store.getters.Campaigns;
    this.loadCompanies();
  },
  computed: {
    filteredList() {
      const vm = this;
      if (!vm.dataTable) {
        return [];
      }
      let searched = vm.dataTable.filter((obj) => {
        return obj.name.toLowerCase().indexOf(vm.search.toLowerCase()) != -1;
      });
      // let searched = vm.dataTable.map((obj) => {
      //   if (Object.keys(obj).some((key) => obj[key].toString().indexOf(vm.search) != -1))
      //     return obj;
      // });
      return searched.filter((obj) => obj != undefined);
    },
    show(n) {
      return this.dataTable.slice(0, n);
    },

    StatusTable() {
      return this.$store.getters.Loading;
    },
    darkMode() {
      return this.$parent.darkMode;
    },
  },
  methods: {
    loadCompanies(page = 1) {
      this.dataTable = null; // Comment for smooth update
      this.$apiClient
        .getCompanies()
        .then((resp) => {
          this.dataTable = resp.data.data.map((dt) =>
            Object.assign({}, defaultCompanyData, dt)
          );
        })
        .catch((err) => {});
    },

    drag(itemID) {
      const item = this.dataSrc.find((items) => items.id == itemID);
      console.log(item);
    },
    action(todo, id) {
      console.log(todo + "-" + id);
      //
      const item = this.dataTable.find((items) => items.id == id);
      if (todo == "Copy") {
        this.showModal();
        this.$emit("createCampaignsInitData", item);
      } else if (todo == "Edit") {
        this.showModal();
        this.$emit("createCampaignsEditData", item);
      } else if (todo == "Delete") {
        const isSure = confirm(`Вы уверены что хотите удалить кампанию '${item.name}'?`);
        if (isSure) {
          this.$apiClient.deleteCompany(item.id).then((resp) => {
            this.loadCompanies();
          });
        }
      }
    },
    set(text) {
      this.$store.dispatch("filteredCampaigns", text);
    },
    selectAction(action) {
      this.selectedAction = action;
    },
    apply() {
      console.log(this.selectedAction);
    },
    // refresh() {
    //   this.$store.dispatch("getCampaigns");

    //   this.dataTable = this.$store.getters.Campaigns;
    // },
    setRows(n) {
      this.defaultRows = n;
    },
    showModal() {
      this.isModalVisible = true;
      this.$emit("createCampaignShow");
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onCampaignCreated() {
      console.debug("Campaign created, refreshing info");
      this.loadCompanies();
    },
  },
};
</script>

<style lang="scss">
.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}

.circle-status {
  font-size: 0;
  margin: 0 8px;
  margin-right: 10px;
  position: relative;
  top: 0px;
}
.click-play {
  margin: 0px 8px;
  margin-right: 25px;
  height: 24px;
  font-size: 0;

  .dark & {
    #bg {
      fill: red !important;
    }
  }
}
.campaigns-container {
  table > tr > th {
    position: relative;

    span.subname {
      position: absolute;
      bottom: 7px;
      left: 2px;
    }
  }
}
.dark {
  .table-stat {
    &.percent {
      color: #ebebeb;
    }
    &.total {
      color: #ebebeb;
    }
  }
}
</style>
