<template>
  <div class="settings-container">
    <tariffs-list
      :userProfile="userProfile"
      v-show="isModalTariffsVisible"
      @selected="onTariffSelected"
      @close="isModalTariffsVisible = false"
    />

    <div class="head">
      <h1>Настройки</h1>
    </div>

    <div class="settings-tabs">
      <settings-tab :activeTab.sync="activeTab" :num="1">Настройки аккаунта</settings-tab>
      <settings-tab :activeTab.sync="activeTab" :num="2">IP профиль</settings-tab>
      <settings-tab :activeTab.sync="activeTab" :num="3">Clo-фильтр</settings-tab>
    </div>

    <div class="settings-content" v-if="userProfile">
      <!-- Tab user profile -->
      <div class="tab-1 profile-container" v-if="activeTab == 1">
        <div class="profile-avatar">
          <!-- <img src="//via.placeholder.com/150x150?text=Avatar" /> -->
          <img src="https://www.thispersondoesnotexist.com/image?11628953216" />
          <svg
            v-svg
            symbol="icon_edit_3"
            size="0 0 12 12"
            role="presentation"
            class="edit-icon"
          ></svg>
        </div>

        <!-- <button
          type="button"
          class="btn button button-prımary button-danger large"
          @click="accountLogout()"
        >
          Выйти из аккаунта
        </button> -->

        <!-- Basic settings -->
        <div class="profile-basic">
          <h2>Настройки аккаунта</h2>

          <div class="form-group form-h">
            <label>Почта</label>
            <input type="text" class="c-input" v-model="userProfile.email" disabled />
            <a class="text-theme link-edit">Ред.</a>
          </div>

          <div class="form-group form-h">
            <label>Имя пользователя</label>
            <input type="text" class="c-input" v-model="userProfile.username" disabled />
            <a class="text-theme link-edit">Ред.</a>
          </div>
        </div>

        <!-- Password change -->

        <div class="profile-password wfixed">
          <h2>Сменить пароль</h2>

          <div class="form-group form-h">
            <label>Текущий пароль</label>
            <input
              :type="form.passwordVisible ? 'text' : 'password'"
              class="c-input"
              placeholder="••••••••"
              v-model="form.passwordCurrent"
            />
            <a
              href="#"
              class="icon-theme link-edit"
              @click.prevent="form.passwordVisible = !form.passwordVisible"
            >
              <svg
                v-if="form.passwordVisible"
                v-svg
                symbol="icon_eye"
                size="0 0 16 16"
                role="presentation"
              ></svg>
              <svg
                v-else
                v-svg
                symbol="icon_eye-off"
                size="0 0 16 16"
                role="presentation"
              ></svg>
            </a>
          </div>

          <div class="form-group form-h">
            <label>Новый пароль</label>
            <input
              type="text"
              class="c-input"
              :class="{
                'is-invalid':
                  form.passwordNew1 &&
                  form.passwordNew2 &&
                  form.passwordNew1 !== form.passwordNew2,
              }"
              placeholder="••••••••"
              v-model="form.passwordNew1"
            />
          </div>

          <div class="form-group form-h">
            <label>Подтвердите пароль</label>
            <div class="form-input-group">
              <input
                type="text"
                class="c-input"
                :class="{
                  'is-invalid':
                    form.passwordNew1 &&
                    form.passwordNew2 &&
                    form.passwordNew1 !== form.passwordNew2,
                }"
                placeholder="••••••••"
                v-model="form.passwordNew2"
              />

              <div class="form-input-after">
                <div
                  class="text-end text-bold"
                  :class="form.passwordUpdated ? 'text-ok' : 'text-err'"
                >
                  <template v-if="form.passwordUpdated == false">
                    Неверный текущий пароль
                  </template>
                  <template v-else-if="form.passwordUpdated == true">
                    Пароль обновлен
                  </template>
                </div>
                <div class="text-theme text-end cursor-pointer" @click="updatePassword()">
                  Обновить пароль
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="profile-tariff">
          <h2>Тариф</h2>

          <div class="form-group form-h wfixed lauto flex-between flex-align-center">
            <label class="m-0">Текущий тарифф</label>
            <h3>
              <template v-if="userTariff">
                {{ userTariff.name }}
              </template>
              <template v-else> не подключен </template>
            </h3>
            <span class="text-theme font-bold cursor-pointer" @click="openChangeTariff()"
              >Сменить тариф</span
            >
          </div>
          <div
            class="form-group form-h wfixed lauto flex-between flex-align-center"
            v-if="userTariff"
          >
            <label class="m-0">Заканчивается</label>
            <h3>
              {{ formatDate(userTariff.ends_at) }}
              <small class="profile-tariff-left">
                {{ formatDateLeft(userTariff.ends_at) }}
              </small>
            </h3>
            <span class="text-theme font-bold cursor-pointer">Продлить</span>
          </div>
        </div>

        <div class="settings-other wfixed">
          <h2>Другие настройки</h2>

          <div class="form-group form-h">
            <label>Часовой пояс</label>
            <c-dropselect :options="['(UTC +3) Москва, Санкт-Петербург']"></c-dropselect>
            <!-- <select class="c-input c-select">
              <option value="+3">(UTC +3) Москва, Санкт-Перербург</option>
            </select> -->
          </div>

          <div class="form-group form-h">
            <label>Язык интерфейса</label>
            <c-dropselect :options="['Русский']"></c-dropselect>
            <!-- <select class="c-input c-select">
              <option value="ru">Русский</option>
            </select> -->
          </div>

          <div class="form-group form-h">
            <label>API-ключ</label>
            <input
              type="password"
              class="c-input"
              placeholder="***********"
              autocomplete="off"
            />
            <a class="text-theme link-edit">Показать</a>
          </div>

          <div class="flex flex-justify-between width-fixed mt-50">
            <button
              type="button"
              class="btn button button-prımary button-danger large"
              @click="accountLogout()"
            >
              Выйти из аккаунта
            </button>
            <button class="btn button button-primary large">
              Сохранить все настройки
            </button>
          </div>
        </div>
      </div>

      <!-- Tab IP settings -->
      <div class="tab-2" v-if="activeTab == 2">
        <h2>IP Профиль</h2>
        <div class="form-group form-h wfixed">
          <label>Добавить IP адреса</label>
          <tags-input
            wrapper-class="tags-input-wrapper-default c-input"
            element-id="tags-ips"
            v-model="form.IPS"
            :add-tags-on-comma="true"
            :add-tags-on-space="true"
            :typeahead-hide-discard="true"
            :validate="IPValidate"
            typeahead-style="dropdown"
            placeholder="Начните ввод..."
          ></tags-input>
        </div>

        <div class="ips-submit">
          <button
            class="btn button button-primary"
            :disabled="forms.ips.isSaving"
            @click="IPSSave()"
          >
            <div class="loading-spinner white wtext" v-if="forms.ips.isSaving" />
            Сохранить
          </button>
        </div>

        <div class="ips-status" v-if="forms.ips.result !== null">
          <span class="text-success" v-if="forms.ips.result"> Успешно сохранено </span>
          <span class="text-danger" v-else> Ошибка сохранения </span>
        </div>
      </div>

      <!-- Tab Clo-filter -->
      <div class="tab-3 clo-items-tab" v-if="activeTab == 3">
        <h2>Clo-фильтр</h2>

        <div class="form-group">
          <div class="flex w100">
            <div class="progress">
              <div class="progress" style="height: 20px">
                <div class="progress" style="height: 20px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ width: CLOPrc + '%' }"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <span class="text-muted">{{ CLOPrc }}%</span>
          </div>
          <div class="text-muted m-min">Уровень защиты</div>
        </div>

        <div class="clo-items-row form-group row">
          <div class="clo-item" v-for="item of CLOItems" :key="item.id">
            <label class="c-check">
              <input type="checkbox" v-model="item.val" />
              <div class="checkmark"></div>
              {{ item.name }}
            </label>
          </div>
        </div>

        <button class="btn button button-primary large">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import settingsTab from "@/components/Tabs/settings-tab.vue";
import TagsInput from "@voerro/vue-tagsinput";
import TariffsList from "@/components/TariffsList.vue";
import CIcon from "@/components/Icon/c-icon.vue";
import { setAccessToken } from "@/api/localstorage.js";
import CDropselect from "@/components/Form/с-dropselect.vue";

let CLOItems = [
  {
    name: "Проверка IP по Black лист",
    id: "ip_blacklist",
    prc: 10,
    val: true,
  },
  {
    name: "Проверка User агентов",
    id: "user_agents",
    prc: 10,
    val: true,
  },
];

for (const i of Array(10).keys()) {
  CLOItems.push({
    name: "Item #" + i,
    id: "item_" + i,
    val: false,
  });
}

export default {
  components: { settingsTab, TagsInput, TariffsList, CIcon, CDropselect },
  data() {
    return {
      activeTab: 1,
      form: {
        passwordCurrent: "",
        passwordNew1: "",
        passwordNew2: "",
        passwordUpdated: null,
        passwordVisible: false,
        IPS: [],
      },
      forms: {
        ips: {
          isSaving: false,
          result: null,
        },
        clo: {
          isSaving: false,
          result: null,
        },
      },
      CLOItems: CLOItems,
      isModalTariffsVisible: false,
    };
  },
  methods: {
    formState(name, isSaving, result, err) {
      let f = this.forms[name];
      if (isSaving !== undefined) {
        f.isSaving = isSaving;
      }
      if (result !== undefined) {
        f.result = result;
        setTimeout(() => {
          f.result = null;
        }, 5000);
      }
      if (err) {
        window.LastHTTPError = err;
      }
    },
    selectTab(num) {
      this.activeTab = num;
    },
    updatePassword() {
      if (this.form.passwordNew1 !== this.form.passwordNew2) {
        return;
      }
      this.form.passwordUpdated = null;
      this.$apiClient
        .updateUserPassword(
          this.form.passwordCurrent,
          this.form.passwordNew1,
          this.form.passwordNew2
        )
        .then((resp) => {
          this.form.passwordUpdated = true;
          this.form.passwordCurrent = "";
          this.form.passwordNew1 = "";
          this.form.passwordNew2 = "";
        })
        .catch((err) => {
          this.form.passwordUpdated = false;
        });
    },
    IPValidate(ip) {
      return Boolean(
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          ip
        )
      );
    },
    IPSSave() {
      if (this.form.IPS.length < 1) {
        return;
      }
      this.formState("ips", true);
      this.$apiClient
        .updateIPS({ ips: this.form.IPS.map((o) => o.value).join(", ") })
        .then((resp) => {
          this.formState("ips", false, true);
        })
        .catch((err) => {
          this.formState("ips", false, false, err);
        });
    },
    accountLogout() {
      let isConfirmed = confirm("Вы уверены что хотите выйти из аккаунта?");
      if (isConfirmed) {
        setAccessToken("");
        this.$router.push("/app/login").catch(() => {});
        this.$parent.getUserProfile();
      }
    },
    openChangeTariff() {
      this.isModalTariffsVisible = true;
    },
    onTariffSelected(tariff_id) {
      console.log("Tariff selected: ", tariff_id);
    },
    //
    formatDate(date_raw) {
      let date = new Date(date_raw);
      return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
    },
    formatDateLeft(date_raw) {
      let now = new Date();
      let date = new Date(date_raw);
      var one_day = 1000 * 60 * 60 * 24;
      let days = Math.ceil(Math.abs(now - date) / one_day);

      if (now > date) {
        // Old
        return `Прошло (${days} дней)`;
      } else {
        // Current
        return `Осталось (${days} дней)`;
      }

      return "...";
    },
  },
  mounted() {
    this.activeTab = Number(this.$route.query.tab) || 1;
    this.form.IPS = this.userProfile.ips.split(",").map((ip) => {
      return { key: "", value: ip };
    });
  },
  computed: {
    userProfile() {
      return this.$parent.userProfile;
    },
    userTariff() {
      return this.$parent.userTariff;
    },
    CLOPrc() {
      let prc = 0;
      let prcStatic = 0;
      let prcStaticSumm = 0;
      let prcDynamicItems = 0;
      let prcDynamicItemsCount = 0;

      for (const item of this.CLOItems) {
        if (item.prc) {
          prcStatic += item.prc;
          prcStaticSumm += item.prc;
          if (item.val) {
            prc += item.prc;
          }
        } else {
          prcDynamicItemsCount += 1;
          if (item.val) {
            prcDynamicItems += 1;
          }
        }
      }

      let prcTotalItems = this.CLOItems.length;
      let prcDynamicItem = (100 - prcStaticSumm) / prcDynamicItemsCount;
      let prcDynamic = prcDynamicItems * prcDynamicItem;
      prc += prcDynamic;

      return prc;
    },
  },
  watch: {
    $route(to, from) {
      if (to.name == "Settings") {
        this.activeTab = Number(this.$route.query.tab) || 1;
      }
    },
    activeTab(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.$router.push({ query: { tab: this.activeTab } }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-tabs {
  display: flex;
  margin: 3rem 0;
}

.settings {
  &-content {
    margin-bottom: 30px;
  }
}

h2 {
  margin: 4rem 0;
  color: #2d4350;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
}
.width-fixed {
  max-width: 600px;
}

.form-input {
  &-after {
    margin-top: 20px;
  }
}
.settings-container {
  .form-group {
    display: flex;
    justify-content: flex-start;
    // align-items: flex-start;
    & > {
      line-height: 40px;
      min-height: 40px;
    }

    & label:first-child {
      width: 300px;
      font-weight: bold;
      font-size: 16px;
      line-height: 110%;
    }
    &.lauto label:first-child {
      width: auto;
    }
    &.wfixed {
      max-width: 600px;
    }

    &.flex-around {
      justify-content: space-around;
    }
    &.flex-between {
      justify-content: space-between;
    }
  }
}
.profile {
  &-avatar {
    margin: 2rem 0;
    height: 90px;
    width: 90px;
    position: relative;
    // box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 50%;

    img {
      border-radius: 50%;
      height: 100%;
    }

    & .edit-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      //
      background: #0052f2;
      border-radius: 50%;
      fill: white;
      padding: 6px 6px;
      box-sizing: content-box;
      width: 10px;
      height: 10px;
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      transition: ease 0.2s;
      &:hover {
        box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.35);
      }
    }
  }

  &-container {
    label {
      font-weight: bold;
      color: #2d4350;
    }
  }

  &-tariff {
    font-size: 16px;
    &-left {
      display: block;
      font-size: 16px;
      line-height: 130%;
      font-weight: 600;
    }
    h3 {
      color: #6e7687;
      min-width: 150px;
      font-size: 21px;
      font-weight: normal;
    }
    span {
      min-width: 120px;
      text-align: right;
    }
    label {
      min-width: 140px;
    }
  }
}
.text-end {
  text-align: right;
}
.text-bold {
  font-weight: bold;
}
.btn {
  display: inline;
}
.c-select {
  width: 300px;
}
.text-muted {
  color: #aeb9bd;
}
.progress {
  height: 20px;
  width: 100%;
  background: #d6daea;
  border-radius: 7px;

  .progress-bar {
    height: 100%;
    background: linear-gradient(90deg, #3035a9 0%, #8045e0 97.91%);
    border-radius: 7px;
    transition: all 0.2s ease 0s;
  }
  & + span {
    margin-left: 0.2rem;
    line-height: 120%;
  }
}
.m-min {
  margin: 0.5rem 0;
}

.clo-items-tab {
  width: 700px;
  button {
    padding: 10px 27px;
    margin: 0;
  }
}

.clo-items-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 1rem;
  row-gap: 1rem;
  height: auto;

  .clo-item {
    width: 40%;
  }
}

.link {
  &-edit {
    align-self: center;
    margin-left: 10px;
  }
}
.w100 {
  width: 100%;
}
.c-input::placeholder {
  color: #aeb9bd;
}
</style>
