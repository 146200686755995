<template>
  <modal
    @close="close()"
    :title="isExists ? 'Редактирование кампании' : 'Создание кампании'"
    class="modal-create-campaign"
  >
    <form class="form-container" @submit.prevent="companyCreate()">
      <div class="form-create">
        <div class="form-col">
          <div class="form-group">
            <label> Имя кампании </label>
            <input
              class="c-input"
              :class="fieldCls('name')"
              type="text"
              v-model="campaign.name"
              placeholder="Имя кампании"
            />

            <div class="form-subgroup flex f-align-center">
              <label class="c-check">
                <input type="checkbox" name="" id="" />
                <div class="checkmark"></div>
                Запомнить настройки кампании?
              </label>
            </div>
            <!-- Check Запомнить настройки кампании? -->
          </div>
          <div class="form-group">
            <label> Страна <c-tooltip /> </label>
            <!-- <input class="c-input" type="text" v-model="campaign.countries" /> -->
            <tags-input
              element-id="tags-countries"
              v-model="campaign.countriesData"
              :only-existing-tags="true"
              :existing-tags="countriesList"
              :typeahead="true"
              :add-tags-on-comma="true"
              :add-tags-on-space="true"
              :typeahead-hide-discard="true"
              typeahead-style="dropdown"
              placeholder="Начните ввод..."
            ></tags-input>
            <div class="form-subgroup flex flex-justify-around">
              <c-radio :val.sync="campaign.countries_status" onval="disable">
                Отключен
              </c-radio>
              <c-radio :val.sync="campaign.countries_status" onval="allow">
                Допускать
              </c-radio>
              <c-radio :val.sync="campaign.countries_status" onval="disallow">
                Запрещать
              </c-radio>
            </div>
          </div>
          <div class="form-group">
            <label> IP фильтр </label>
            <!-- <input class="c-input" type="text" v-model="campaign.ips" /> -->
            <tags-input
              element-id="tags-ips"
              v-model="campaign.ipsData"
              :add-tags-on-comma="true"
              :add-tags-on-space="true"
              :typeahead-hide-discard="true"
              :validate="IPValidate"
              typeahead-style="dropdown"
              placeholder="Начните ввод..."
            ></tags-input>
            <div class="form-subgroup flex flex-justify-around">
              <c-radio :val.sync="campaign.ip_status" onval="disable"> Отключен </c-radio>
              <c-radio :val.sync="campaign.ip_status" onval="allow"> Допускать </c-radio>
              <c-radio :val.sync="campaign.ip_status" onval="disallow">
                Запрещать
              </c-radio>
            </div>
          </div>
        </div>

        <div class="form-delim"></div>

        <div class="form-col">
          <div class="form-group">
            <label> Trafic page <c-tooltip /> </label>
            <c-dropselect
              :options="['Вариант 1', 'Вариант 2']"
              placeholder="Trafic page"
              select="action"
              width="300px"
            >
            </c-dropselect>
          </div>
          <div class="form-group">
            <label class="label-black"> Black page <c-tooltip /> </label>

            <div class="input-actions">
              <c-dropselect
                :options="['Вариант 1', 'Вариант 2']"
                placeholder="Black page"
                select="action"
                width="300px"
              >
              </c-dropselect>
              <svg
                v-svg
                symbol="icon_download"
                size="0 0 24 24"
                role="presentation"
                class="c-icons"
              ></svg>
            </div>
          </div>
          <div class="form-group">
            <label class="label-white"> White page <c-tooltip></c-tooltip> </label>
            <div class="input-actions">
              <c-dropselect
                :options="['Вариант 1', 'Вариант 2']"
                placeholder="White page"
                select="action"
                width="300px"
              >
              </c-dropselect>
              <svg
                v-svg
                symbol="icon_download"
                size="0 0 24 24"
                role="presentation"
                class="c-icons"
              ></svg>
            </div>
          </div>
          <div class="form-group">
            <label> Рекламная сеть <c-tooltip></c-tooltip> </label>
            <div class="input-actions">
              <c-dropselect
                :options="['Вариант 1', 'Вариант 2']"
                placeholder="Рекламная сеть"
                select="action"
                width="300px"
                :disabled="!campaign.ad_network_enable"
              />
              <c-switch :checked.sync="campaign.ad_network_enable" design="small" />
            </div>
          </div>
        </div>
      </div>

      <div class="form-submit flex flex-center">
        <button type="button" @click="close()" class="btn button button-transparent">
          Отменить
        </button>
        <button
          type="submit"
          class="button button-primary large font-bold"
          :class="{ disabled: isLoading }"
        >
          <div class="loading-spinner white wtext" v-if="isLoading" />
          <template>
            <span v-if="isExists">Сохранить</span>
            <span v-else> Создать кампанию </span>
          </template>
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import CRadio from "@/components/Form/c-radio.vue";
import LoadingIndicator from "../Loading/loading-indicator.vue";
import TagsInput from "@voerro/vue-tagsinput";
import countriesList from "@/components/data/countryList.js";
import CTooltip from "../Tooltip/c-tooltip.vue";
import CDropselect from "../Form/с-dropselect.vue";
import CSwitch from "../Form/switch.vue";

function getCountriesList() {
  let res = [];
  for (const country of countriesList) {
    res.push({
      key: country.alpha2,
      value: `[${country.alpha2}] ${country.name}`,
    });
  }
  return res;
}
function ReArr(arr) {
  return JSON.parse(JSON.stringify(arr));
}

const campaignDefault = {
  id: null,
  name: "",
  countries: "",
  countriesData: [],
  countries_status: "disable",
  ips: "",
  ipsData: [],
  ip_status: "disable",
  trafic_page: "",
  status: false,
  ad_network_enable: false,
};

export default {
  data() {
    return {
      campaign: Object.assign({}, campaignDefault),
      formErrors: null,
      isLoading: false,
      countriesList: getCountriesList(),
    };
  },
  components: {
    Modal,
    CRadio,
    LoadingIndicator,
    TagsInput,
    CTooltip,
    CDropselect,
    CSwitch,
  },
  mounted() {
    this.$parent.$on("createCampaignsInitData", (dt) => {
      Object.assign(this.campaign, dt, { id: null, trafic_page: dt.trafic });
    });
    this.$parent.$on("createCampaignsEditData", (dt) => {
      Object.assign(this.campaign, dt, { trafic_page: dt.trafic });
    });
    this.$parent.$on("createCampaignShow", (dt) => {
      Object.assign(this.campaign, campaignDefault);
    });
  },
  methods: {
    close() {
      this.$emit("close");
    },
    //
    fieldCls(field) {
      if (this.formErrors) {
        let err_code = this.formErrors[field];
        // console.log("Field err:", field, err_code);
        if (err_code) {
          return ["is-invalid"];
        } else {
          // return ["is-valid"];
        }
      }
    },
    formValidate() {
      let errors = {};
      let comp = this.campaign;
      if (!comp.name) {
        errors.name = "Обязательное поле";
      }
      if (!comp.trafic_page) {
        errors.trafic_page = "Обязательное поле";
      }

      //
      this.formErrors = errors;
      return Object.getOwnPropertyNames(errors).length - 1 < 1; // -1 because observator
    },
    //
    companyCreate() {
      console.debug("companyCreate");
      if (!this.formValidate()) {
        return;
      }
      let comp = this.campaign;
      this.isLoading = true;
      console.log("Sending form!", "Company exists:", this.isExists);
      //
      if (this.isExists) {
        this.$apiClient
          .updateCompany(comp.id, comp)
          .then((resp) => {
            this.isLoading = false;
            Object.assign(this.campaign, resp.data);
            this.close();
            this.$emit("campaignCreated");
          })
          .catch((err) => {
            this.isLoading = false;
          });
      } else {
        this.$apiClient
          .createCompany(comp)
          .then((resp) => {
            // Object.assign(this.campaign, resp.data);
            //
            this.isLoading = false;
            Object.assign(this.campaign, campaignDefault);
            this.close();
            this.$emit("campaignCreated");
          })
          .catch((err) => {
            this.isLoading = false;
          });
      }
    },
    IPValidate(ip) {
      return Boolean(
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          ip
        )
      );
    },
  },
  computed: {
    isExists() {
      return this.campaign.id !== null;
    },
  },
  watch: {
    "campaign.countriesData": function (newVal, oldVal) {
      if (newVal && newVal.length > 0 && ReArr(newVal) !== ReArr(oldVal)) {
        // console.debug(newVal);
        this.campaign.countries = newVal
          .map((obj) => {
            return obj.key.toLowerCase();
          })
          .join(", ");
      } else {
        // this.campaign.countries = "";
      }
    },
    "campaign.countries": function (newVal, oldVal) {
      if (newVal && ReArr(newVal) != ReArr(oldVal)) {
        let newArr = [];
        for (const country of newVal.split(", ")) {
          let val = this.countriesList.find((obj) => {
            return obj.key == country.toUpperCase() ? obj : null;
          });
          // console.debug("Country convert:", country, val);
          if (val) {
            newArr.push(val);
          }
        }
        this.campaign.countriesData = newArr;
      }
    },
    "campaign.ipsData": function (newVal, oldVal) {
      if (newVal && newVal.length > 0 && ReArr(newVal) != ReArr(oldVal)) {
        console.debug(newVal, oldVal);
        this.campaign.ips = newVal
          .map((obj) => {
            return obj.value;
          })
          .join(", ");
        console.debug(
          newVal.map((obj) => {
            return obj.value;
          })
        );
      }
    },
    "campaign.ips": function (newVal, oldVal) {
      if (newVal && ReArr(newVal) != ReArr(oldVal)) {
        let newArr = newVal
          .split(", ")
          .filter((obj) => {
            return obj;
          })
          .map((obj) => {
            return obj ? { key: obj, value: obj } : false;
          });
        // console.debug(newArr);
        if (ReArr(this.campaign.ipsData) != newArr) {
          this.campaign.ipsData = newArr;
        }
      }
    },
  },
};
</script>

<style lang="scss">
// .close-icon {
//   border: none;
//   cursor: pointer;
//   font-weight: bold;
//   fill: rgba(167, 177, 192, 1);
//   background: transparent;
//   position: absolute;
//   right: -100px;
// }
.modal-create-campaign .modal-container {
  max-width: 840px;
}
.button-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

.form-submit {
  margin-top: 60px;
}

.modal-create-campaign {
  .c-select,
  .c-input {
    width: 300px;
  }
  .modal-header {
    margin-left: 10px;
  }
}

.modal-create-campaign .form-group {
  margin-bottom: 40px;

  & > label:first-child {
    margin-bottom: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.form-create {
  .form-group {
    > label {
      font-size: 17px;
    }
  }
}

// .form-create .c-input {
//   width: 330px;
// }
.form-subgroup {
  margin-top: 0.5rem;
}
.form-delim {
  border-left: 1px solid #d6daea;

  .dark & {
    border-color: #34384a;
  }
}
.tags-input {
  max-width: 330px;
}
.label-black {
  color: #0ab4bf;
  .dark & {
    color: #0ab4bf;
  }
}
.label-white {
  color: #dc9b12;
  .dark & {
    color: #dc9b12;
  }
}
</style>
