import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style.scss';
import TrendChart from "vue-trend-chart";
import SvgSprite from 'vue-svg-sprite';
import icons from "./assets/icons.svg"
import ApiClient from "@/api/api_client.js";



Vue.use(SvgSprite, {
  url: icons,
  class: 'my-class',
});


Vue.use(TrendChart);

Vue.config.productionTip = false
Vue.prototype.$apiClient = new ApiClient()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
