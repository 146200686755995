<template>
  <div class="flex-center loading-indicator-container" v-if="show">
    <div class="loading-spinner"></div>
    <span> Загрузка... </span>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
