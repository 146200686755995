<template>
  <div class="container">
    <div class="head">
      <h1>Статистика по кампаниям</h1>
    </div>

    <c-tabs>
      <c-tab active="true" label="Общая статистика">
        <total style="margin-top: 25px"></total>
      </c-tab>
      <c-tab label="Статистика фильтрованных кликов">
        <filter-clicks> </filter-clicks>
      </c-tab>
    </c-tabs>
  </div>
</template>

<script>
import CTab from "../Tabs/c-tab.vue";
import CTabs from "../Tabs/c-tabs.vue";
import Total from "../Modules/Total.vue";
import FilterClicks from "../Modules/FilterClicks.vue";
export default {
  components: {
    CTabs,
    CTab,
    Total,
    FilterClicks,
  },
};
</script>

<style>
.continer {
  color: rgba(45, 67, 80, 1);
}

.head {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

h2 {
  margin: 0;
}
</style>
