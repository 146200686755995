<template>
  <div class="container">
    <div class="head">
      <div class="actions-head-left">
        <h1>Домены</h1>
      </div>

      <c-button type="primary" size="large" icon="icon_plus" @click="showModal()">
        Добавить домен
      </c-button>
    </div>

    <c-table
      actionPlaceholder="Действия с доменами"
      :columns="columns"
      :dataSrc="dataSrc"
      cheked
      :dataPerPage="defaultRows"
      id="domains"
    >
      <span slot="name" class="name">
        <span> Имя </span>
        <span class="subname"> id </span>
      </span>

      <span slot="names" slot-scope="record">
        <div class="campaign-name">
          <span class="cname"> {{ record["record"]["domain"] }}</span>
          <span class="csubname"> {{ record["record"]["subname"] }}</span>
        </div>
      </span>

      <span slot="total">
        <svg
          v-svg
          symbol="icon_users"
          size="0 0 20 20"
          role="presentation"
          fill="red"
          class="c-icons users"
        ></svg>
      </span>

      <span slot="status" slot-scope="record">
        <span style="color: rgba(41, 159, 21, 1)"> Активен</span>
      </span>

      <span slot="action" slot-scope="record">
        <div class="action" style="display: flex; flex-direction: row">
          <span
            @click="action('Stat', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_external-link"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>

          <span
            @click="action('Edit', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_edit"
              size="0 0 20 20"
              role="presentation"
              class="c-icons edit"
            ></svg>
          </span>
          <span
            @click="action('Delete', record['record']['id'])"
            style="cursor: pointer; margin: 10px"
          >
            <svg
              v-svg
              symbol="icon_trash_2"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
          <span class="handle" style="margin-left: 20px; margin-top: 10px">
            <svg
              v-svg
              symbol="icon_drag_and_drop"
              size="0 0 20 20"
              role="presentation"
              class="c-icons"
            ></svg>
          </span>
        </div>
      </span>
    </c-table>
    <domain-create
      v-show="isModalVisible"
      @close="closeModal"
      @newCreated="newCreated()"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    width: "100",
  },
  {
    dataIndex: "domain",
    key: "domain",
    slots: { title: "name" },
    width: "190",
    scopedSlots: { customRender: "names" },
  },
  {
    dataIndex: "whitepage",
    key: "whitepage",
    width: "200",
    title: "Привязан к странице",
  },
  {
    dataIndex: "clicks",
    key: "clicks",
    width: "200",
    slots: { title: "total" },
  },
  {
    dataIndex: "date",
    key: "date",
    width: "200",
    title: "Дата",
  },
  {
    title: "Статус",
    key: "status",
    width: "260",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "Действия",
    key: "action",
    width: "260",
    scopedSlots: { customRender: "action" },
  },
];

const dataSrc = [
  {
    id: 1,
    domain: "https://go-it.com",
    subname: "cbdj536",
    clicks: 0,
    whitepage: "null",
    date: "2015-06-21",
  },
];
import CSwitch from "../Form/c-switch.vue";
import CTable from "../Table/c-table.vue";
import CButton from "../Button/c-button.vue";
import DomainCreate from "../Modules/DomainCreate.vue";

export default {
  data() {
    return {
      columns,
      defaultRows: 5,
      dataSrc,
      isModalVisible: false,
    };
  },
  components: { CButton, CTable, CSwitch, DomainCreate },
  methods: {
    action(todo, id) {
      console.log(todo + "-" + id);
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showModal() {
      this.isModalVisible = true;
    },
    newCreated() {},
  },
};
</script>

<style></style>
