<template>
  <div class="editor-wrapper">
    <div class="editor-controls" v-if="editor">
      <div class="controls-group">
        <button
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          <svg
            v-svg
            symbol="icon_font_bold"
            :size="iconSize"
            role="presentation"
            class="editor-icon"
          ></svg>
        </button>
        <button
          @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <svg
            v-svg
            symbol="icon_font_italic"
            :size="iconSize"
            role="presentation"
            class="editor-icon"
          ></svg>
        </button>
        <button
          @click="editor.chain().focus().toggleUnderline().run()"
          :class="{ 'is-active': editor.isActive('underline') }"
        >
          <svg
            v-svg
            symbol="icon_font_underlined"
            :size="iconSize"
            role="presentation"
            class="editor-icon"
          ></svg>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        >
          <svg
            v-svg
            symbol="icon_font_title"
            :size="iconSize"
            role="presentation"
            class="editor-icon"
          ></svg>
        </button>
      </div>
      <!-- <button @click="editor.chain().focus().unsetAllMarks().run()">clear marks</button>
      <button @click="editor.chain().focus().clearNodes().run()">clear nodes</button> -->

      <div class="delim"></div>
      <!--  -->

      <div class="controls-group">
        <button
          @click="editor.chain().focus().setTextAlign('left').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
        >
          <svg
            v-svg
            symbol="icon_justify_left"
            :size="iconSize"
            role="presentation"
            class="editor-icon"
          ></svg>
        </button>
        <button
          @click="editor.chain().focus().setTextAlign('center').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
        >
          <svg
            v-svg
            symbol="icon_justify_center"
            :size="iconSize"
            role="presentation"
            class="editor-icon"
          ></svg>
        </button>

        <button
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <svg
            v-svg
            symbol="icon_list"
            :size="iconSize"
            role="presentation"
            class="editor-icon"
          ></svg>
        </button>
      </div>

      <div class="delim"></div>

      <div class="controls-group">
        <button @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
          <svg
            v-svg
            symbol="icon_link_h"
            :size="iconSize"
            role="presentation"
            class="editor-icon"
          ></svg>
        </button>
        <button
          @click="editor.chain().focus().unsetLink().run()"
          v-if="editor.isActive('link')"
        >
          unsetLink
        </button>
      </div>

      <!-- <button
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
      >
        ordered list
      </button> -->

      <div class="delim"></div>
      <!--  -->
      <div class="controls-group">
        <button
          @click="editor.chain().focus().undo().run()"
          :class="{ 'is-active': editor.can().undo() }"
          :disabled="!editor.can().undo()"
        >
          <svg
            v-svg
            symbol="icon_arr_left"
            :size="iconSize"
            role="presentation"
            class="editor-icon"
          ></svg>
        </button>
        <button
          @click="editor.chain().focus().redo().run()"
          :class="{ 'is-active': editor.can().redo() }"
          :disabled="!editor.can().redo()"
        >
          <svg
            v-svg
            symbol="icon_arr_right"
            :size="iconSize"
            role="presentation"
            class="editor-icon"
          ></svg>
        </button>
      </div>
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
// import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Heading from "@tiptap/extension-heading";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import History from "@tiptap/extension-history";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
//
import CIcon from "@/components/Icon/c-icon.vue";

export default {
  components: {
    EditorContent,
    CIcon,
  },

  props: {
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      editor: null,
      iconSize: "0 0 15 15",
    };
  },

  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
    },
  },

  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        // StarterKit,
        Document,
        Paragraph,
        Text,
        Underline,
        Bold,
        Italic,
        Link,
        BulletList,
        ListItem,
        History,
        Heading.configure({
          levels: [1, 2, 3],
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
      ],
      placeholder: this.palceholder,
      content: this.modelValue,
      onUpdate: () => {
        // HTML
        this.$emit("update:modelValue", this.editor.getHTML());

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
$lightEditorToolsBg: #ffffff;
$lightEditorBg: #ffffff;
$lightIcon: #a7b1c0;
$lightIconActive: #6f809e;
$lightBorder: #d6daea;
//
$darkEditorToolsBg: #252938;
$darkEditorBg: #1f222b;
$darkIcon: #a9b6cb;
$darkIconActive: #6d84ad;
$darkBorder: #34384a;

//
.editor-wrapper {
  border: 1px solid $lightBorder;

  .dark & {
    border-color: $darkBorder;
  }

  .editor-controls {
    background: $lightEditorToolsBg;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid $lightBorder;

    .dark & {
      background: $darkEditorToolsBg;
      border-color: $darkBorder;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      margin-right: 10px;

      svg {
        fill: $lightIcon;
      }
      &.is-active svg {
        fill: $lightIconActive;
      }

      .dark & {
        svg {
          fill: $darkIcon;
        }
        &.is-active svg {
          fill: $darkIconActive;
        }
      }
    }

    .delim {
      margin: 0 10px;
    }
  }

  .ProseMirror {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 250px;
    max-height: 500px;
    background: $lightEditorBg;
    font-size: 14px;
    color: #aeb9bd;
    //
    resize: vertical;
    overflow: auto;
    outline: none !important;
    padding: 9px 12px;
    line-height: 140%;
    letter-spacing: 0.2px;

    .dark & {
      background: $darkEditorBg;
    }

    // Display styles
    p {
      margin: 0.5em 0;
    }
    ul {
      padding-inline-start: 40px;
      list-style-type: disc;
    }
  }
}
</style>
